import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch, faEdit } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const NewsScreen = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({
    show: false,
    newsItem: null,
  });
  const [editModalState, setEditModalState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNews, setFilteredNews] = useState([]);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [shares, setShares] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [selectedNewsItem, setSelectedNewsItem] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        console.log("Fetching news from Firestore...");
        const snapshot = await firebase
          .firestore()
          .collection("newsandfeed")
          .get();

        if (snapshot.empty) {
          console.log("No matching documents.");
          setLoading(false);
          return;
        }

        const newsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt.toDate().toLocaleDateString(),
          };
        });

        console.log("Fetched news data: ", newsData);
        setNews(newsData);
        setFilteredNews(newsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news: ", error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    setFilteredNews(
      news.filter((newsItem) =>
        newsItem.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        newsItem.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        newsItem.author?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, news]);

  const handleDelete = (newsItem) => {
    setModalState({ show: true, newsItem });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("newsandfeed")
        .doc(modalState.newsItem.id)
        .delete();
      setNews((prev) =>
        prev.filter((item) => item.id !== modalState.newsItem.id)
      );
      setFilteredNews((prev) =>
        prev.filter((item) => item.id !== modalState.newsItem.id)
      );
      setModalState({ show: false, newsItem: null });
    } catch (error) {
      console.error("Error deleting news item: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, newsItem: null });
  };

  const handleEditCounts = (newsItem) => {
    setSelectedNewsItem(newsItem);
    setLikes(newsItem.likes || 0);
    setDislikes(newsItem.dislikes || 0);
    setShares(newsItem.shareCount || 0);
    setClicks(newsItem.clicksCount || 0);
    setEditModalState(true);
  };

  const saveCounts = async () => {
    if (selectedNewsItem) {
      try {
        await firebase
          .firestore()
          .collection("newsandfeed")
          .doc(selectedNewsItem.id)
          .update({
            likes: parseInt(likes, 10),
            dislikes: parseInt(dislikes, 10),
            shareCount: parseInt(shares, 10),
            clicksCount: parseInt(clicks, 10),
          });

        setNews((prev) =>
          prev.map((item) =>
            item.id === selectedNewsItem.id
              ? { ...item, likes: parseInt(likes, 10), dislikes: parseInt(dislikes, 10), shareCount: parseInt(shares, 10), clicksCount: parseInt(clicks, 10) }
              : item
          )
        );

        setFilteredNews((prev) =>
          prev.map((item) =>
            item.id === selectedNewsItem.id
              ? { ...item, likes: parseInt(likes, 10), dislikes: parseInt(dislikes, 10), shareCount: parseInt(shares, 10), clicksCount: parseInt(clicks, 10) }
              : item
          )
        );

        setEditModalState(false);
        setSelectedNewsItem(null);
      } catch (error) {
        console.error("Error updating counts: ", error);
      }
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total News: {filteredNews.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search News..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainerBlogs">
        {filteredNews.map((newsItem) => (
          <div key={newsItem.id} className="contentItemBlogs">
            <h3 className="title">{newsItem.title}</h3>
            <p>{newsItem.content}</p>
            <div
              className="userInfo"
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div>
                <img
                  src={newsItem.imageUrl}
                  alt={newsItem.title}
                  className="profileImageBlogs"
                />
              </div>
              <div>
                <p className="name" style={{ marginBottom: "0px" }}>
                  <strong style={{ fontWeight: "500" }}>Author: </strong>
                  {newsItem.author}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Date: </strong>
                  {newsItem.createdAt}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>ID: </strong>
                  {newsItem.id}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Image Url: </strong>
                  {newsItem.imageUrl}
                </p>
              </div>
            </div>
            <div className="counts-container" style={{ display: "flex", gap: "20px", alignItems: 'center', justifyContent: 'center', margin: '15px 0' }}>
              <span>Likes: {newsItem.likes || 0}</span>
              <span>Dislikes: {newsItem.dislikes || 0}</span>
              <span>Shares: {newsItem.shareCount || 0}</span>
              <span>Clicks: {newsItem.clicksCount || 0}</span>
              <button className="edit-icon" onClick={() => handleEditCounts(newsItem)} style={{marginTop: 0, backgroundColor: '#eee', padding: '10px 20px'}}>
              <FontAwesomeIcon icon={faEdit} color="green" fontSize={20}/>
              </button>
              <button
                className="delete-icon"
                onClick={() => handleDelete(newsItem)}
                style={{marginTop: 0, backgroundColor: '#eee', padding: '10px 20px'}}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this news item?`}
        />
      )}
      {editModalState && (
        <div className="modal-payment">
        <div className="modal-content-payment">
          <h3>Edit Counts</h3>
          <div className="input-group">
            <label htmlFor="likes">Likes:</label>
            <input
              type="number"
              id="likes"
              value={likes}
              onChange={(e) => setLikes(e.target.value)}
              placeholder="Likes"
            />
          </div>
          <div className="input-group">
            <label htmlFor="dislikes">Dislikes:</label>
            <input
              type="number"
              id="dislikes"
              value={dislikes}
              onChange={(e) => setDislikes(e.target.value)}
              placeholder="Dislikes"
            />
          </div>
          <div className="input-group">
            <label htmlFor="shares">Shares:</label>
            <input
              type="number"
              id="shares"
              value={shares}
              onChange={(e) => setShares(e.target.value)}
              placeholder="Shares"
            />
          </div>
          <div className="input-group">
            <label htmlFor="clicks">Clicks:</label>
            <input
              type="number"
              id="clicks"
              value={clicks}
              onChange={(e) => setClicks(e.target.value)}
              placeholder="Clicks"
            />
          </div>
          <div className="modal-buttons">
            <button className="button button-save" onClick={saveCounts}>
              Save
            </button>
            <button
              className="button button-cancel"
              onClick={() => setEditModalState(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      
      )}
    </>
  );
};

export default NewsScreen;
