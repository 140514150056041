import React, { useState } from "react";
import "./Payments.css"; // For consistent styling
import NewsScreen from "./NewsScreen";
import BlogsScreen from "./BlogScreen";
import VideosScreen from "./VideosScreen";
import NewsUpload from "./NewsUpload";

const Uploads = () => {
  const [activeTab, setActiveTab] = useState("News");

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "News":
        return <NewsScreen />;
      case "Blogs":
        return <BlogsScreen />;
      case "Bytes":
        return <VideosScreen />;
      // case "News Upload":
      //   return <NewsUpload />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="sub-tabs">
        <div
          className={`sub-tab ${activeTab === "News" ? "active" : ""}`}
          onClick={() => setActiveTab("News")}
        >
          News
        </div>
        <div
          className={`sub-tab ${activeTab === "Blogs" ? "active" : ""}`}
          onClick={() => setActiveTab("Blogs")}
        >
          Blogs
        </div>
        <div
          className={`sub-tab ${activeTab === "Bytes" ? "active" : ""}`}
          onClick={() => setActiveTab("Bytes")}
        >
          Bytes
        </div>
        {/* <div
          className={`sub-tab ${activeTab === "News Upload" ? "active" : ""}`}
          onClick={() => setActiveTab("News Upload")}
        >
          News Upload
        </div> */}
      </div>

      <div className="sub-tab-content">{renderActiveTabContent()}</div>
    </div>
  );
};

export default Uploads;
