import React from "react";
import "./ConfirmationModal.css";

const ConfirmationModal = ({ show, onClose, onConfirm, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <p>{message}</p>
        <div className="modal-buttons">
          <button onClick={onConfirm} className="modal-confirm-button">
            Yes
          </button>
          <button onClick={onClose} className="modal-close-button">
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
