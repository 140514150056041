import React, { useState } from "react";
import MuxUploader from "@mux/mux-uploader-react";

const VideoUpload = () => {
  const [uploadUrl, setUploadUrl] = useState(null);

  // Fetch signed upload URL
  const getUploadUrl = async () => {
    try {
      const response = await fetch(
        "https://us-central1-lexdo-7b0b1.cloudfunctions.net/createMuxDirectUpload",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: "user123", caption: "Sample Caption", language: "English" }),
        }
      );
      const data = await response.json();
      if (data.uploadUrl) {
        setUploadUrl(data.uploadUrl);
      } else {
        console.error("Error fetching upload URL");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle upload completion
  const handleUploadComplete = (event) => {
    const playbackUrl = `https://stream.mux.com/${event.target.muxAssetId}.m3u8`;
    const thumbnailUrl = `https://image.mux.com/${event.target.muxAssetId}/thumbnail.png`;
    
    // Log URLs to the console
    console.log("Playback URL:", playbackUrl);
    console.log("Thumbnail URL:", thumbnailUrl);
  };

  return (
    <div>
      <h2>Upload Your Video</h2>
      {!uploadUrl ? (
        <button onClick={getUploadUrl}>Get Upload URL</button>
      ) : (
        <MuxUploader endpoint={uploadUrl} onUploadComplete={handleUploadComplete} />
      )}
    </div>
  );
};

export default VideoUpload;
