import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "./CategoryModal.css";

const CategoryModal = ({ closeModal, service, category, services, refreshServices }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [mainContent, setMainContent] = useState("");
  const [viewMore, setViewMore] = useState("");
  const [documents, setDocuments] = useState("");
  const [image, setImage] = useState(null);
  const [videoUrl, setVideoUrl] = useState(""); // Updated state for video URL
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    if (service) {
      setTitle(service.title || "");
      setDescription(service.description || "");
      setPrice(service.price || "");
      setMainContent(service.mainContent || "");
      setViewMore(service.viewMore || "");
      setDocuments((service.documents || []).join(", "));
      setImage(service.image || null);
      setVideoUrl(service.videoUrl || ""); // Set video URL if available
    }
  }, [service]);

  const handleSubmit = async () => {
    if (!title || !description || !price) return;
  
    setLoading(true); // Set loading to true
  
    try {
      const serviceData = {
        title,
        description,
        price,
        mainContent,
        viewMore,
        videoUrl, // Use videoUrl from state
      };
  
      // Conditionally add the documents field if not empty
      if (documents.trim()) {
        serviceData.documents = documents.split(",").map((doc) => doc.trim());
      }
  
      // Upload image to Firebase Storage and get URL
      const storageRef = firebase.storage().ref();
  
      if (image && typeof image !== "string") {
        const imageRef = storageRef.child(`images/${image.name}`);
        await imageRef.put(image);
        const imageUrl = await imageRef.getDownloadURL();
        serviceData.imageUrl = imageUrl;
      } else if (service && service.imageUrl) {
        // Retain the existing imageUrl if a new image is not uploaded
        serviceData.imageUrl = service.imageUrl;
      }
  
      if (service) {
        // Update existing service
        const updatedServices = services.map((s) =>
          s.title === service.title ? serviceData : s
        );
        await firebase.firestore().collection("services").doc(category).update({
          services: updatedServices,
        });
      } else {
        // Add new service
        await firebase.firestore().collection("services").doc(category).set(
          {
            services: firebase.firestore.FieldValue.arrayUnion(serviceData),
          },
          { merge: true }
        );
      }
  
      // Refresh services list
      refreshServices(category);
  
      closeModal();
    } catch (error) {
      console.error("Error adding/updating service: ", error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };  

  return (
    <div className="category-modal">
      <div className="modal-content">
        <h2>{service ? "Edit Service" : "Add Service"}</h2>
        {loading && <div className="loader">Loading...</div>} {/* Loader */}
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Price</label>
          <input
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Main Content</label>
          <textarea
            value={mainContent}
            onChange={(e) => setMainContent(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>View More</label>
          <textarea
            value={viewMore}
            onChange={(e) => setViewMore(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Documents</label>
          <input
            type="text"
            value={documents}
            onChange={(e) => setDocuments(e.target.value)}
            placeholder="Comma separated list"
          />
        </div>
        <div className="form-group">
          <label>Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label>Video URL</label>
          <input
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)} // Update video URL state
          />
        </div>
        <div className="form-actions">
          <button onClick={handleSubmit} disabled={loading}>
            Save
          </button>
          <button onClick={closeModal} disabled={loading}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
