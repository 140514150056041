import React, { useState, useEffect } from 'react';
import { collection, getFirestore, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import './FAQsAdmin.css'; // Ensure this file exists

const FAQsAdmin = () => {
  const [faqs, setFaqs] = useState([]);
  const [question, setQuestion] = useState('');
  const [videoId, setVideoId] = useState('');
  const [order, setOrder] = useState('');

  // Fetch FAQs
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const db = getFirestore();
        const snapshot = await getDocs(collection(db, 'FAQs'));
        const fetchedFaqs = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.order - b.order); // Sort by order
        setFaqs(fetchedFaqs);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };
    fetchFAQs();
  }, []);

  // Add a new FAQ
  const handleAddFAQ = async () => {
    try {
      const db = getFirestore();
      const newFAQ = { question, videoId, order: parseInt(order, 10) || 0 };
      const docRef = await addDoc(collection(db, 'FAQs'), newFAQ);
      setFaqs([...faqs, { id: docRef.id, ...newFAQ }].sort((a, b) => a.order - b.order));
      setQuestion('');
      setVideoId('');
      setOrder('');
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };

  // Delete an FAQ
  const handleDeleteFAQ = async (id) => {
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, 'FAQs', id));
      setFaqs(faqs.filter(faq => faq.id !== id));
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };

  return (
    <div className="faqs-admin">
      <h2>FAQs Management</h2>
      <div className="faq-form">
        <input
          type="text"
          placeholder="Enter question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter YouTube video ID"
          value={videoId}
          onChange={(e) => setVideoId(e.target.value)}
        />
        <input
          type="number"
          placeholder="Enter order"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
        />
        <button onClick={handleAddFAQ}>Add FAQ</button>
      </div>
      <ul>
        {faqs.map((faq) => (
          <li key={faq.id} className='faqActions'>
            <span>
              <b>Order:</b> {faq.order} - <b>Question:</b> {faq.question} -{' '}
              <a href={`https://youtu.be/${faq.videoId}`} target="_blank" rel="noreferrer">
                Video
              </a>
            </span>
            <button onClick={() => handleDeleteFAQ(faq.id)} className="delete-btn" style={{marginTop: 0}}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQsAdmin;
