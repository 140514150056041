import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./ContentPage.css";
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";
import userIcon from "./Assets/user-icon.png";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase.firestore().collection("users").doc(userId).get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
      email: userData?.email || "",
      phoneNumber: userData?.phoneNumber || ""
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "", email: "", phoneNumber: "" };
  }
};

const LexshieldScreen = () => {
  const [lexshieldMembers, setLexshieldMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({ show: false, member: null });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);

  useEffect(() => {
    const fetchLexshieldMembers = async () => {
      try {
        const snapshot = await firebase.firestore().collection("communities").orderBy("joinedAt", "desc").get();
        const members = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = members.map((member) => member.userId);
        const userDetailsPromises = userIds.map((userId) => fetchUserNameAndImage(userId));
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setLexshieldMembers(members);
        setFilteredMembers(members);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Lexshield members: ", error);
        setLoading(false);
      }
    };

    fetchLexshieldMembers();
  }, []);

  useEffect(() => {
    setFilteredMembers(
      lexshieldMembers.filter((member) => {
        const userDetail = userDetails[member.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          userDetail.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          userDetail.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [searchTerm, lexshieldMembers, userDetails]);

  const handleDelete = (member) => {
    setModalState({ show: true, member });
  };

  const confirmDelete = async () => {
    try {
      await firebase.firestore().collection("communities").doc(modalState.member.id).delete();
      setLexshieldMembers((prev) => prev.filter((item) => item.id !== modalState.member.id));
      setFilteredMembers((prev) => prev.filter((item) => item.id !== modalState.member.id));
      setModalState({ show: false, member: null });
    } catch (error) {
      console.error("Error deleting Lexshield member: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, member: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Lexshield Members: {filteredMembers.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Members..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredMembers.map((member) => {
          const userDetail = userDetails[member.userId] || {};
          return (
            <div key={member.id} className="contentItem">
              <p className="ItemHeading">Lex Shield Member</p>
              <div className="profileImageContainer">
                <img
                  src={userDetail.profileImage || userIcon}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Email:</p>
                <p className="detail">{userDetail.email}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Phone Number:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Video URL:</p>
                <p className="detail">
                <p className="detail"><a href={member.videoURL} target="_blank" rel="noopener noreferrer" style={{backgroundColor: '#500523', color: '#fff', padding: '5px 15px', textDecoration: 'none', borderRadius: 20, fontSize: 16}}>view</a></p>
                </p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Joined At:</p>
                <p className="detail">
                  {member.joinedAt
                    ? new Date(member.joinedAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : "Unknown"}
                </p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(member);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this Lexshield member?`}
        />
      )}
    </>
  );
};

export default LexshieldScreen;
