// import React, { useState, useEffect } from "react";
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import Loader from "./Loader";
// import styles from "./NotificationUser.module.css";

// // Initialize Firestore
// const firestore = firebase.firestore();

// const NotificationUser = () => {
//   const [userId, setUserId] = useState("");
//   const [message, setMessage] = useState("");
//   const [sending, setSending] = useState(false);
//   const [allUsers, setAllUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState("");

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const snapshot = await firestore.collection("users").get();
//         const users = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setAllUsers(users);
//         setFilteredUsers(users);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setLoading(false);
//       }
//     };
//     fetchUsers();
//   }, []);

//   useEffect(() => {
//     const lowerCaseTerm = searchTerm.toLowerCase();
//     setFilteredUsers(
//       allUsers.filter(
//         (user) =>
//           user.id.toLowerCase().includes(lowerCaseTerm) ||
//           (user.name || "").toLowerCase().includes(lowerCaseTerm) ||
//           (user.email || "").toLowerCase().includes(lowerCaseTerm) ||
//           (user.phoneNumber || "").toLowerCase().includes(lowerCaseTerm)
//       )
//     );
//   }, [searchTerm, allUsers]);

//   const sendNotification = async (userId, message) => {
//     try {
//       await firestore.collection("allNotifications").add({
//         userId,
//         message,
//         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//       });
//     } catch (error) {
//       console.error("Error sending notification:", error);
//     }
//   };

//   const handleSend = async () => {
//     setSending(true);
//     try {
//       if (userId) {
//         // Send to one user
//         await sendNotification(userId, message);
//       } else {
//         // Send to all users
//         const notificationPromises = filteredUsers.map((user) =>
//           sendNotification(user.id, message)
//         );
//         await Promise.all(notificationPromises);
//       }
//       alert("Notification sent successfully!");
//       setMessage("");
//       setUserId("");
//     } catch (error) {
//       console.error("Error sending notifications:", error);
//       alert("Failed to send notifications.");
//     } finally {
//       setSending(false);
//     }
//   };

//   if (loading) {
//     return (
//       <div className={styles.loadingContainer}>
//         <Loader />
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <h2 className={styles.heading}>Send Notification</h2>
//       <div className={styles.form}>
//         <label className={styles.label}>Search User:</label>
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Search by ID, Name, Email, or Phone Number..."
//           className={styles.searchInput}
//         />

//         <label className={styles.label}>Select User:</label>
//         <select
//           value={userId}
//           onChange={(e) => setUserId(e.target.value)}
//           className={styles.select}
//         >
//           <option value="">All Users</option>
//           {filteredUsers.map((user) => (
//             <option key={user.id} value={user.id}>
//               {user.name || "Unknown User"} - {user.id}
//             </option>
//           ))}
//         </select>

//         <label className={styles.label}>Message:</label>
//         <textarea
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//           placeholder="Type your notification message..."
//           className={styles.textarea}
//           rows="4"
//         />

//         <button
//           onClick={handleSend}
//           className={styles.sendButton}
//           disabled={sending}
//         >
//           {sending ? "Sending..." : "Send Notification"}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default NotificationUser;



// import React, { useState, useEffect } from "react";
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";
// import Loader from "./Loader";
// import styles from "./NotificationUser.module.css";

// const firestore = firebase.firestore();
// const storage = firebase.storage();

// const NotificationUser = () => {
//   const [userId, setUserId] = useState("");
//   const [message, setMessage] = useState("");
//   const [imageUrl, setImageUrl] = useState("");
//   const [sending, setSending] = useState(false);
//   const [uploading, setUploading] = useState(false);
//   const [allUsers, setAllUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedGroup, setSelectedGroup] = useState("All Users");

//   const collectionMap = {
//     "All Users": { collection: "users", nameField: "name" },
//     Lawyers: { collection: "approvedLawyers", nameField: "fullName" },
//     Auditors: { collection: "approvedAuditors", nameField: "fullName" },
//     Consultants: { collection: "approvedConsultants", nameField: "fullName" },
//     "Law Firms": { collection: "approvedLawFirms", nameField: "firmName" },
//     "Audit Firms": { collection: "approvedAuditFirms", nameField: "firmName" },
//     "Private Detectives": { collection: "approvedPrivateDetectives", nameField: "fullName" },
//   };

//   useEffect(() => {
//     if (!selectedGroup) return;

//     const fetchUsers = async () => {
//       setLoading(true);
//       const { collection, nameField } = collectionMap[selectedGroup];
//       try {
//         const snapshot = await firestore.collection(collection).get();
//         const users = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           name: doc.data()[nameField] || "Unknown",
//           ...doc.data(),
//         }));
//         setAllUsers(users);
//         setFilteredUsers(users);
//       } catch (error) {
//         console.error("Error fetching users:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsers();
//   }, [selectedGroup]);

//   useEffect(() => {
//     const lowerCaseTerm = searchTerm.toLowerCase();
//     setFilteredUsers(
//       allUsers.filter(
//         (user) =>
//           user.id.toLowerCase().includes(lowerCaseTerm) ||
//           (user.name || "").toLowerCase().includes(lowerCaseTerm) ||
//           (user.email || "").toLowerCase().includes(lowerCaseTerm) ||
//           (user.phoneNumber || "").toLowerCase().includes(lowerCaseTerm)
//       )
//     );
//   }, [searchTerm, allUsers]);

//   const sendNotification = async (userId, message, imageUrl) => {
//     try {
//       await firestore.collection("allNotifications").add({
//         userId,
//         message,
//         imageUrl,
//         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//       });
//     } catch (error) {
//       console.error("Error sending notification:", error);
//     }
//   };

//   const handleSend = async () => {
//     setSending(true);
//     try {
//       if (userId) {
//         await sendNotification(userId, message, imageUrl);
//       } else {
//         const notificationPromises = filteredUsers.map((user) =>
//           sendNotification(user.id, message, imageUrl)
//         );
//         await Promise.all(notificationPromises);
//       }
//       alert("Notification sent successfully!");
//       setMessage("");
//       setUserId("");
//       setImageUrl("");
//     } catch (error) {
//       console.error("Error sending notifications:", error);
//       alert("Failed to send notifications.");
//     } finally {
//       setSending(false);
//     }
//   };

//   const handleImageUpload = async (event) => {
//     const file = event.target.files[0];
//     if (!file) return;

//     setUploading(true);
//     const storageRef = storage.ref(`notificationImages/${file.name}`);
//     try {
//       await storageRef.put(file);
//       const url = await storageRef.getDownloadURL();
//       setImageUrl(url);
//       alert("Image uploaded successfully!");
//     } catch (error) {
//       console.error("Error uploading image:", error);
//       alert("Failed to upload image.");
//     } finally {
//       setUploading(false);
//     }
//   };

//   if (loading) {
//     return (
//       <div className={styles.loadingContainer}>
//         <Loader />
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <h2 className={styles.heading}>Send Notification</h2>
//       <div className={styles.form}>
//         <label className={styles.label}>User Group:</label>
//         <select
//           value={selectedGroup}
//           onChange={(e) => setSelectedGroup(e.target.value)}
//           className={styles.select}
//         >
//           {Object.keys(collectionMap).map((group) => (
//             <option key={group} value={group}>
//               {group}
//             </option>
//           ))}
//         </select>

//         <label className={styles.label}>Search User:</label>
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Search by ID, Name, Email, or Phone Number..."
//           className={styles.searchInput}
//         />

//         <label className={styles.label}>Select User:</label>
//         <select
//           value={userId}
//           onChange={(e) => setUserId(e.target.value)}
//           className={styles.select}
//         >
//           <option value="">All Users</option>
//           {filteredUsers.map((user) => (
//             <option key={user.id} value={user.id}>
//               {user.name} - {user.id}
//             </option>
//           ))}
//         </select>

//         <label className={styles.label}>Message:</label>
//         <textarea
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//           placeholder="Type your notification message..."
//           className={styles.textarea}
//           rows="4"
//         />

//         <label className={styles.label}>Image URL:</label>
//         <input
//           type="text"
//           value={imageUrl}
//           onChange={(e) => setImageUrl(e.target.value)}
//           placeholder="Enter image URL or upload one..."
//           className={styles.imageUrlInput}
//         />

//         <label htmlFor="file-upload" className={styles.uploadButton}>
//         Choose Image
//         </label>
//         <input
//         type="file"
//         id="file-upload"
//         onChange={handleImageUpload}
//         className={styles.fileInput}
//         />

//         {uploading && <p>Uploading...</p>}

//         {imageUrl && (
//           <div className={styles.imageContainer}>
//             <img src={imageUrl} alt="Preview" className={styles.imagePreview} />
//           </div>
//         )}

//         <button
//           onClick={handleSend}
//           className={styles.sendButton}
//           disabled={sending}
//         >
//           {sending ? "Sending..." : "Send Notification"}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default NotificationUser;


import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import Loader from "./Loader";
import styles from "./NotificationUser.module.css";

const firestore = firebase.firestore();
const storage = firebase.storage();

const NotificationUser = () => {
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [sending, setSending] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("All Users");
  const [notificationType, setNotificationType] = useState("general"); // New state for notification type
  const [contentId, setContentId] = useState(""); // New state for Blog/News ID

  const collectionMap = {
    "All Users": { collection: "users", nameField: "name" },
    Lawyers: { collection: "approvedLawyers", nameField: "fullName" },
    Auditors: { collection: "approvedAuditors", nameField: "fullName" },
    Consultants: { collection: "approvedConsultants", nameField: "fullName" },
    "Law Firms": { collection: "approvedLawFirms", nameField: "firmName" },
    "Audit Firms": { collection: "approvedAuditFirms", nameField: "firmName" },
    "Private Detectives": { collection: "approvedPrivateDetectives", nameField: "fullName" },
  };

  useEffect(() => {
    if (!selectedGroup) return;

    const fetchUsers = async () => {
      setLoading(true);
      const { collection, nameField } = collectionMap[selectedGroup];
      try {
        const snapshot = await firestore.collection(collection).get();
        const users = snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data()[nameField] || "Unknown",
          ...doc.data(),
        }));
        setAllUsers(users);
        setFilteredUsers(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [selectedGroup]);

  useEffect(() => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    setFilteredUsers(
      allUsers.filter(
        (user) =>
          user.id.toLowerCase().includes(lowerCaseTerm) ||
          (user.name || "").toLowerCase().includes(lowerCaseTerm) ||
          (user.email || "").toLowerCase().includes(lowerCaseTerm) ||
          (user.phoneNumber || "").toLowerCase().includes(lowerCaseTerm)
      )
    );
  }, [searchTerm, allUsers]);

  const sendNotification = async (userId, message, imageUrl, type, contentId) => {
    try {
      await firestore.collection("allNotifications").add({
        userId,
        message,
        imageUrl,
        type, // Add type (general, blog, news)
        contentId, // Add content ID (for blog/news)
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const handleSend = async () => {
    setSending(true);
    try {
      if (userId) {
        // Send to one user
        await sendNotification(userId, message, imageUrl, notificationType, contentId);
      } else {
        // Send to all users
        const notificationPromises = filteredUsers.map((user) =>
          sendNotification(user.id, message, imageUrl, notificationType, contentId)
        );
        await Promise.all(notificationPromises);
      }
      alert("Notification sent successfully!");
      setMessage("");
      setUserId("");
      setImageUrl("");
      setContentId(""); // Reset content ID
    } catch (error) {
      console.error("Error sending notifications:", error);
      alert("Failed to send notifications.");
    } finally {
      setSending(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    const storageRef = storage.ref(`notificationImages/${file.name}`);
    try {
      await storageRef.put(file);
      const url = await storageRef.getDownloadURL();
      setImageUrl(url);
      alert("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to upload image.");
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Send Notification</h2>
      <div className={styles.form}>
        <label className={styles.label}>User Group:</label>
        <select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          className={styles.select}
        >
          {Object.keys(collectionMap).map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </select>

        <label className={styles.label}>Notification Type:</label>
        <select
          value={notificationType}
          onChange={(e) => setNotificationType(e.target.value)}
          className={styles.select}
        >
          <option value="general">General</option>
          <option value="blog">Blog</option>
          <option value="news">News</option>
        </select>

        {notificationType !== "general" && (
          <div>
            <label className={styles.label}>
              {notificationType === "blog" ? "Blog ID:" : "News ID:"}
            </label>
            <input
              type="text"
              value={contentId}
              onChange={(e) => setContentId(e.target.value)}
              placeholder={`Enter ${notificationType} ID`}
              className={styles.searchInput}
            />
          </div>
        )}

        <label className={styles.label}>Search User:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by ID, Name, Email, or Phone Number..."
          className={styles.searchInput}
        />

        <label className={styles.label}>Select User:</label>
        <select
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          className={styles.select}
        >
          <option value="">All Users</option>
          {filteredUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name} - {user.id}
            </option>
          ))}
        </select>

        <label className={styles.label}>Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your notification message..."
          className={styles.textarea}
          rows="4"
        />

        <label className={styles.label}>Image URL:</label>
        <input
          type="text"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          placeholder="Enter image URL or upload one..."
          className={styles.imageUrlInput}
        />

        <label htmlFor="file-upload" className={styles.uploadButton}>
          Choose Image
        </label>
        <input
          type="file"
          id="file-upload"
          onChange={handleImageUpload}
          className={styles.fileInput}
        />

        {uploading && <p>Uploading...</p>}

        {imageUrl && (
          <div className={styles.imageContainer}>
            <img src={imageUrl} alt="Preview" className={styles.imagePreview} />
          </div>
        )}

        <button
          onClick={handleSend}
          className={styles.sendButton}
          disabled={sending}
        >
          {sending ? "Sending..." : "Send Notification"}
        </button>
      </div>
    </div>
  );
};

export default NotificationUser;
