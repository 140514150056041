import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnNZFV6k-9ZpokQR_d5syekQU9JgHQEa8",
  authDomain: "lexdo-7b0b1.firebaseapp.com",
  projectId: "lexdo-7b0b1",
  storageBucket: "lexdo-7b0b1.appspot.com",
  messagingSenderId: "323278730681",
  appId: "1:323278730681:web:82a5e0300cea57b897f268",
  measurementId: "G-5VQFBFWKDZ",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
