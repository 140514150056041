import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import "./ContentPage.css";

const ScholarshipUpload = () => {
  const [title, setTitle] = useState("");
  const [providedBy, setProvidedBy] = useState(""); // Provided by field
  const [category, setCategory] = useState(""); // Lexdoo or Others category
  const [scholarshipType, setScholarshipType] = useState(""); // Paid or Service
  const [amount, setAmount] = useState(""); // Amount field for Paid option
  const [validity, setValidity] = useState(null); // Scholarship validity with Date object
  const [providerName, setProviderName] = useState(""); // Provider name field
  const [providerEmail, setProviderEmail] = useState(""); // Provider email field
  const [providerMobile, setProviderMobile] = useState(""); // Provider mobile field
  const [eligibility, setEligibility] = useState(""); // Eligibility field
  const [googleFormLink, setGoogleFormLink] = useState(""); // Google Form Link
  const [logo, setLogo] = useState(null); // Logo upload
  const [logoPreview, setLogoPreview] = useState(null); // Logo preview
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleLogoClick = () => {
    document.getElementById("logoInput").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let logoUrl = ""; // Store the logo URL if uploaded

    try {
      // Upload logo if selected
      if (logo) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`logos/${logo.name}`);
        await fileRef.put(logo);
        logoUrl = await fileRef.getDownloadURL();
      }

      // Add the document to Firestore
      const docRef = await firebase.firestore().collection("scholarship").add({
        title,
        providedBy,
        category,
        scholarshipType,
        amount: scholarshipType === "Paid" ? amount : null, // Only add amount if Paid is selected
        validity: validity ? firebase.firestore.Timestamp.fromDate(validity) : null, // Store as Firestore Timestamp
        providerName,
        providerEmail,
        providerMobile,
        eligibility, // Store eligibility
        googleFormLink: category === "Others" ? googleFormLink : null, // Store Google Form Link if Others in Category
        logoUrl, // Store the logo URL
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Get the document ID and update the document with its ID
      const docId = docRef.id;
      await firebase.firestore().collection("scholarship").doc(docId).update({
        id: docId,
      });

      setLoading(false);
      setMessage("Scholarship uploaded successfully!");
      setShowPopup(true);
      clearForm();
    } catch (error) {
      setLoading(false);
      setMessage("Scholarship not uploaded. Please try again.");
      setShowPopup(true);
    }
  };

  const clearForm = () => {
    setTitle("");
    setProvidedBy("");
    setCategory("");
    setScholarshipType("");
    setAmount("");
    setValidity(null);
    setProviderName("");
    setProviderEmail("");
    setProviderMobile("");
    setEligibility("");
    setGoogleFormLink("");
    setLogo(null);
    setLogoPreview(null);
  };

  const closePopup = () => {
    setShowPopup(false);
    setMessage("");
  };

  return (
    <>
      <div className="headingContainer">
        <p className="heading">Upload Scholarship</p>
      </div>
      <div className="uploadContainer">
        <form onSubmit={handleSubmit} className="uploadForm">
          <label>Title</label>
          <input
            type="text"
            value={title}
            placeholder="e.g. Lexdoo Scholarship 2024"
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <label>Provided By</label>
          <input
            type="text"
            value={providedBy}
            placeholder="e.g. Lexdoo"
            onChange={(e) => setProvidedBy(e.target.value)}
            required
          />

          <label>Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            style={{
              height: 40,
              border: "1px solid #ccc",
              borderRadius: "4px",
              paddingLeft: 5,
              marginBottom: 10,
            }}
          >
            <option value="" disabled>Select a category</option>
            <option value="Lexdoo">Lexdoo</option>
            <option value="Others">Others</option>
          </select>

          <label>Scholarship Type</label>
          <select
            value={scholarshipType}
            onChange={(e) => setScholarshipType(e.target.value)}
            required
            style={{
              height: 40,
              border: "1px solid #ccc",
              borderRadius: "4px",
              paddingLeft: 5,
              marginBottom: 10,
            }}
          >
            <option value="" disabled>Select Scholarship Type</option>
            <option value="Paid">Paid</option>
            <option value="Service">Service</option>
          </select>

          {scholarshipType === "Paid" && (
            <>
              <label>Amount</label>
              <input
                type="number"
                value={amount}
                placeholder="e.g. 5000"
                onChange={(e) => setAmount(e.target.value)}
                required={scholarshipType === "Paid"}
              />
            </>
          )}

          {category === "Others" && (
            <>
              <label>Google Form Link</label>
              <input
                type="text"
                value={googleFormLink}
                placeholder="https://forms.google.com/..."
                onChange={(e) => setGoogleFormLink(e.target.value)}
                required
              />
            </>
          )}

          <label>Scholarship Validity</label>
          <DatePicker
            selected={validity}
            onChange={(date) => setValidity(date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select a date"
            className="datePickerInput"
            required
          />

          <label>Provider Name</label>
          <input
            type="text"
            value={providerName}
            placeholder="e.g. John Doe"
            onChange={(e) => setProviderName(e.target.value)}
            required
          />

          <label>Provider Email</label>
          <input
            type="email"
            value={providerEmail}
            placeholder="e.g. provider@example.com"
            onChange={(e) => setProviderEmail(e.target.value)}
            required
          />

          <label>Provider Mobile Number</label>
          <input
            type="tel"
            value={providerMobile}
            placeholder="e.g. 1234567890"
            onChange={(e) => setProviderMobile(e.target.value)}
            required
          />

          <label>Eligibility</label>
          <textarea
            value={eligibility}
            placeholder="e.g. Must be a final year student with a minimum GPA of 3.0..."
            onChange={(e) => setEligibility(e.target.value)}
            required
          />

          <label>Logo</label>
          <div
            className="imageUploadContainer"
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          >
            <input
              id="logoInput"
              type="file"
              onChange={handleLogoChange}
              style={{ display: "none" }}
            />
            <button type="button">{logo ? logo.name : "Upload Logo"}</button>
          </div>
          {logoPreview && (
            <div className="imagePreviewContainer">
              <img src={logoPreview} alt="Selected" className="imagePreview" />
            </div>
          )}

          <button type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </button>
        </form>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popupContent">
            <p>{message}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default ScholarshipUpload;
