import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";
import MuxPlayer from "@mux/mux-player-react";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const VideosScreen = () => {
  const [approvedVideos, setApprovedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    video: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [editingVideo, setEditingVideo] = useState(null);
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [newThumbnail, setNewThumbnail] = useState("");

  useEffect(() => {
    const fetchApprovedVideos = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedVideos")
          .orderBy("createdAt", "desc")
          .get();
        const videos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = videos.map((video) => video.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedVideos(videos);
        setFilteredVideos(videos);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved videos: ", error);
        setLoading(false);
      }
    };

    fetchApprovedVideos();
  }, []);

  useEffect(() => {
    setFilteredVideos(
      approvedVideos.filter((video) =>
        video.caption?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.language?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userDetails[video.userId]?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, approvedVideos, userDetails]);

  const extractPlaybackId = (url) => {
    // Check for Mux playback URL pattern and extract the ID.
    return url.includes("stream.mux.com") ? url.split("/").pop().split(".")[0] : url;
  };

  const handleEdit = (video) => {
    setEditingVideo(video.id);
    setNewVideoUrl(video.videoUrl || "");
    setNewThumbnail(video.thumbnail || "");
  };

  const handleSave = async (videoId) => {
    try {
      await firebase.firestore().collection("approvedVideos").doc(videoId).update({
        videoUrl: newVideoUrl,
        thumbnail: newThumbnail,
      });
  
      setApprovedVideos((prev) =>
        prev.map((video) =>
          video.id === videoId
            ? { ...video, videoUrl: newVideoUrl, thumbnail: newThumbnail }
            : video
        )
      );
  
      setFilteredVideos((prev) =>
        prev.map((video) =>
          video.id === videoId
            ? { ...video, videoUrl: newVideoUrl, thumbnail: newThumbnail }
            : video
        )
      );
  
      setEditingVideo(null);
      setNewVideoUrl("");
      setNewThumbnail("");
    } catch (error) {
      console.error("Error updating video:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditingVideo(null);
    setNewVideoUrl("");
    setNewThumbnail("");
  };

  const handleDelete = (video) => {
    setModalState({ show: true, video });
  };

  const confirmDelete = async () => {
    const { video } = modalState;
    if (!video) return;

    try {
      const storageRef = firebase.storage().refFromURL(video.videoUrl);
      await storageRef.delete();

      await firebase
        .firestore()
        .collection("approvedVideos")
        .doc(video.id)
        .delete();

      setApprovedVideos((prev) =>
        prev.filter((item) => item.id !== video.id)
      );
      setFilteredVideos((prev) =>
        prev.filter((item) => item.id !== video.id)
      );
      setModalState({ show: false, video: null });
    } catch (error) {
      console.error("Error deleting video: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, video: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Videos: {filteredVideos.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Videos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
      {filteredVideos.map((video) => {
        const userDetail = userDetails[video.userId] || {};
        const isEditing = editingVideo === video.id;
        const playbackId = extractPlaybackId(video.videoUrl); // Extract ID

        return (
          <div key={video.id} className="contentItemVideo">
            <div className="userInfoVideo">
              <img
                src={userDetail.profileImage || require('./Assets/user-icon.png')}
                alt={userDetail.name}
                className="profileImageVideo"
              />
              <p className="name">{userDetail.name}</p>
            </div>

            <div className="detail-container">
              <p className="name label detail">Caption:</p>
              <p className="detail">{video.caption}</p>
            </div>

            <div className="detail-container">
              <p className="name label detail">Language:</p>
              <p className="detail">{video.language}</p>
            </div>

            {isEditing ? (
              <>
                <div className="edit-field" style={{ marginBottom: 20 }}>
                  <label>Video URL:</label>
                  <input
                    type="text"
                    value={newVideoUrl}
                    onChange={(e) => setNewVideoUrl(e.target.value)}
                    placeholder="Edit video URL"
                    style={{
                      padding: 8,
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      marginLeft: 8,
                    }}
                  />
                </div>
                <div className="edit-field">
                  <label>Thumbnail:</label>
                  <input
                    type="text"
                    value={newThumbnail}
                    onChange={(e) => setNewThumbnail(e.target.value)}
                    placeholder="Edit thumbnail URL"
                    style={{
                      padding: 8,
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      marginLeft: 8,
                    }}
                  />
                </div>
                <div style={{ display: "flex", gap: 20 }}>
                  <button
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSave(video.id)}
                  >
                    Save
                  </button>
                  <button
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#f44336",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="videoContainer">
                <MuxPlayer
                  streamType="on-demand"
                  playbackId={playbackId}
                  metadata={{
                    video_id: video.id,
                    video_title: video.caption || "Video",
                  }}
                  controls
                  className="video"
                />
                </div>
                {video.thumbnail && (
                  <img
                    src={video.thumbnail}
                    alt="Thumbnail"
                    className="thumbnail"
                  />
                )}
                <div className="buttonContainer">
                  <button
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "#500523",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => handleEdit(video)}
                  >
                    Edit
                  </button>
                  <button
                    style={{
                      padding: "8px 12px",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(video)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </>
            )}
          </div>
        );
      })}
      </div>

      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this video?`}
        />
      )}
    </>
  );
};

export default VideosScreen;
