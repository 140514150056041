import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Loader from "./Loader";
import "./UsersScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser, faCalendar, faMoneyBillAlt, faClock, faCheckCircle, faPhone, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const TransactionHistory = () => {
  const [transactionsByDate, setTransactionsByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTransactionsByDate, setFilteredTransactionsByDate] = useState({});

  const groupTransactionsByDate = (transactions) => {
    return transactions.reduce((groups, transaction) => {
      const date = transaction.timestamp ? transaction.timestamp.toDate().toLocaleDateString() : 'No Date';
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(transaction);
      return groups;
    }, {});
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDoc = await firebase.firestore().collection("users").doc(userId).get();
      return userDoc.exists ? userDoc.data() : {};
    } catch (error) {
      console.error("Error fetching user details: ", error);
      return {};
    }
  };

  const handleDeleteTransaction = async (transactionId, date) => {
    try {
      await firebase.firestore().collection("transactions").doc(transactionId).delete();
      setTransactionsByDate((prev) => {
        const updatedTransactions = { ...prev };
        updatedTransactions[date] = updatedTransactions[date].filter((transaction) => transaction.id !== transactionId);

        // Remove the date key if no transactions are left for that date
        if (updatedTransactions[date].length === 0) {
          delete updatedTransactions[date];
        }
        return updatedTransactions;
      });
      setFilteredTransactionsByDate((prev) => {
        const updatedFilteredTransactions = { ...prev };
        updatedFilteredTransactions[date] = updatedFilteredTransactions[date].filter((transaction) => transaction.id !== transactionId);

        if (updatedFilteredTransactions[date].length === 0) {
          delete updatedFilteredTransactions[date];
        }
        return updatedFilteredTransactions;
      });
      alert("Transaction deleted successfully.");
    } catch (error) {
      console.error("Error deleting transaction: ", error);
      alert("Failed to delete the transaction. Please try again.");
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const snapshot = await firebase.firestore().collection("transactions").get();
        const transactionsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const transaction = { id: doc.id, ...doc.data() };

            // Fetch user details based on userId
            if (transaction.userId) {
              const userDetails = await fetchUserDetails(transaction.userId);
              transaction.name = userDetails.name || "Unknown";
              transaction.phoneNumber = userDetails.phoneNumber || "Not Provided";
              transaction.email = userDetails.email || "Not Provided";
            }

            return transaction;
          })
        );

        const sortedTransactions = transactionsData.sort((a, b) => {
          const aTimestamp = a.timestamp ? a.timestamp.toMillis() : 0;
          const bTimestamp = b.timestamp ? b.timestamp.toMillis() : 0;
          return bTimestamp - aTimestamp;
        });

        const groupedTransactions = groupTransactionsByDate(sortedTransactions);
        setTransactionsByDate(groupedTransactions);
        setFilteredTransactionsByDate(groupedTransactions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transactions: ", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const filteredTransactions = {};
    Object.keys(transactionsByDate).forEach((date) => {
      const transactions = transactionsByDate[date].filter((transaction) =>
        transaction.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction.receiverName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (transactions.length) {
        filteredTransactions[date] = transactions;
      }
    });
    setFilteredTransactionsByDate(filteredTransactions);
  }, [searchTerm, transactionsByDate]);

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Transactions: {Object.keys(filteredTransactionsByDate).reduce((total, date) => total + filteredTransactionsByDate[date].length, 0)}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Transactions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="users-screen">
        <div className="users-container">
          {Object.keys(filteredTransactionsByDate).map((date) => (
            <div key={date} className="date-group">
              <h3>{date} - {filteredTransactionsByDate[date].length} Requests</h3>
              <div className="users-row">
                {filteredTransactionsByDate[date].map((transaction) => (
                  <div key={transaction.id} className="user-card">
                    <div className="user-details">
                      <h2>{transaction.name} - {transaction.type}</h2>
                      {/* <h3>Type: {transaction.type}</h3> */}
                      <h4>Date: {transaction.timestamp
                            ? new Date(transaction.timestamp.seconds * 1000).toLocaleString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                  hour12: true
                              })
                            : 'Unknown'}</h4>
                      <p><FontAwesomeIcon icon={faPhone} /> {transaction.email}</p>
                      <p><FontAwesomeIcon icon={faPhone} /> {transaction.phoneNumber}</p>
                      <p><FontAwesomeIcon icon={faUser} /> Receiver: {transaction.receiverName}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Total Amount: ₹{transaction.amount}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Total Paid Amount: ₹{transaction.totalAmountPaid}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> central GST: ₹{transaction.centralGST}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> state GST: ₹{transaction.stateGST}</p>
                      <p><FontAwesomeIcon icon={faClock} /> Duration: {transaction.duration} min</p>
                      <p><FontAwesomeIcon icon={faCheckCircle} /> Status: {transaction.status}</p>
                      <p><FontAwesomeIcon icon={faCalendar} /> Transaction Type: {transaction.transactionType}</p>
                    </div>
                    <div className="button-container">
                      <button
                        className="button-delete"
                        onClick={() => handleDeleteTransaction(transaction.id, date)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
