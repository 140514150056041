import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const EventsScreen = () => {
  const [approvedEvents, setApprovedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    event: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const fetchApprovedEvents = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedEvents")
          .orderBy("createdAt", "desc")
          .get();
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = events.map((event) => event.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedEvents(events);
        setFilteredEvents(events);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved events: ", error);
        setLoading(false);
      }
    };

    fetchApprovedEvents();
  }, []);

  useEffect(() => {
    setFilteredEvents(
      approvedEvents.filter((event) => {
        const userDetail = userDetails[event.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.organizer?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.mobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.eventTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.mode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          event.aboutEvent?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [searchTerm, approvedEvents, userDetails]);

  const handleDelete = (event) => {
    setModalState({ show: true, event });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedEvents")
        .doc(modalState.event.id)
        .delete();
      setApprovedEvents((prev) =>
        prev.filter((item) => item.id !== modalState.event.id)
      );
      setFilteredEvents((prev) =>
        prev.filter((item) => item.id !== modalState.event.id)
      );
      setModalState({ show: false, event: null });
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, event: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Events: {filteredEvents.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Events..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredEvents.map((event) => {
          const userDetail = userDetails[event.userId] || {};
          return (
            <div key={event.id} className="contentItemEvents">
              <div className="user-info-container">
                <img
                  src={userDetail.profileImage || require('./Assets/user-icon.png')} 
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="name label detail">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Organizer:</p>
                <p className="detail">{event.organizer}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Mobile:</p>
                <p className="detail">{event.mobile}</p>
              </div>
              <div className="detail-container" style={{ flexWrap: 'wrap' }}>
                <p className="name label detail">Email:</p>
                <p className="detail">{event.email}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Title:</p>
                <p className="detail">{event.eventTitle}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Location:</p>
                <p className="detail">{event.location}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Mode:</p>
                <p className="detail">{event.mode}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Entry Fee:</p>
                <p className="detail">{event.entryFee}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Duration:</p>
                <p className="detail">
                  {new Date(event.startDate?.seconds * 1000).toDateString()} to{" "}
                  {event.endDate
                    ? new Date(event.endDate.seconds * 1000).toDateString()
                    : "N/A"}
                </p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Time:</p>
                <p className="detail">
                  {new Date(event.startDate?.seconds * 1000).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}{" "}
                  -{" "}
                  {new Date(event.endDate?.seconds * 1000).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
              <div className="detail-container" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                <p className="detail" style={{ width: '100%' }}>
                  <strong style={{ fontWeight: "500" }}>About: </strong>
                  <span>{event.aboutEvent}</span>
                </p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Fee Amount: </strong>₹{" "}
                  {event.feeAmount}
                </p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>No of Days: </strong>
                  {event.feeDays} Days
                </p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={() => handleDelete(event)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this event?`}
        />
      )}
    </>
  );
};

export default EventsScreen;
