import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "./Payments.css";

// Firebase configuration should already be initialized in your project
const firestore = firebase.firestore();

const toolsList = [
  "Merge PDF",
  "Split PDF",
  "Compress PDF",
  "PDF OCR",
  "PDF to JPG",
  "Image to PDF",
  "Unlock PDF",
  "Watermark PDF",
  "Office to PDF",
  "Repair PDF",
  "Protect PDF",
  "PDF to PDF/A",
  "Validate PDF/A",
  "Extract PDF Text",
  "Resize Image",
  "Crop Image",
  "Compress Image",
  "Upscale Image",
  "Remove Background",
  "Convert Image",
  "Watermark Image",
  "Page Number PDF",
  "Translate PDF",
  "Speech to Text Live",
  "Word to PDF",
  "Forms",
  "Speech To Translation",
  "PDF to Word",
];

const Payments = () => {
  const [selectedSubTab, setSelectedSubTab] = useState("Events");
  const [opportunityPrice, setOpportunityPrice] = useState('');
  const [eventPrice, setEventPrice] = useState('');
  const [lexiAiPrice, setLexiAiPrice] = useState('');
  const [lexiAiDuration, setLexiAiDuration] = useState('');
  const [lexiAiPricing, setLexiAiPricing] = useState([]);
  const [toolsPricing, setToolsPricing] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isLexiAiModalOpen, setIsLexiAiModalOpen] = useState(false);
  const [isToolModalOpen, setIsToolModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [editToolIndex, setEditToolIndex] = useState(-1);
  const [selectedTool, setSelectedTool] = useState('');
  const [toolPrice, setToolPrice] = useState('');
  const [addressPrice, setAddressPrice] = useState('');
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    description: "",
    userCount: "",
    tokens: "",
    credits: "",
    access: "",
    oneMonth: "",
    threeMonths: "",
    sixMonths: "",
    twelveMonths: "",
    pricingByMonth: [], // [{ month: "1 Month", price: 1000 }]
    additionalCredits: [], // [{ name: "Credit Pack 1", count: 100, price: 95 }]
    additionalTokens: [], // [{ name: "Token Pack 1", count: "Unlimited", price: 500 }]
  });   
  const [editSubscriptionIndex, setEditSubscriptionIndex] = useState(-1);
  const [pdfCredits, setPdfCredits] = useState([]);
  const [isPdfCreditsModalOpen, setIsPdfCreditsModalOpen] = useState(false);
  const [pdfCreditCount, setPdfCreditCount] = useState('');
  const [pdfCreditPrice, setPdfCreditPrice] = useState('');
  const [editPdfCreditIndex, setEditPdfCreditIndex] = useState(-1);
  const [pdfCreditName, setPdfCreditName] = useState('');
  const [lexiAiWebPrice, setLexiAiWebPrice] = useState("");
  const [lexiAiWebDuration, setLexiAiWebDuration] = useState("");
  const [lexiAiWebCredits, setLexiAiWebCredits] = useState("");
  const [lexiAiWebPricing, setLexiAiWebPricing] = useState([]);
  const [isLexiAiWebModalOpen, setIsLexiAiWebModalOpen] = useState(false);
  const [editLexiAiWebIndex, setEditLexiAiWebIndex] = useState(-1);
  const [addLostPrice, setAddLostPrice] = useState('');
  const [chatLostPrice, setChatLostPrice] = useState('');
  const [chatFoundPrice, setChatFoundPrice] = useState('');
  const [isLostAndFoundModalOpen, setIsLostAndFoundModalOpen] = useState(false);


  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const opportunityPriceDoc = await firestore.collection("opportunityPrice").doc("price").get();
        if (opportunityPriceDoc.exists) setOpportunityPrice(opportunityPriceDoc.data().amount);

        const eventPriceDoc = await firestore.collection("eventPrice").doc("price").get();
        if (eventPriceDoc.exists) setEventPrice(eventPriceDoc.data().amount);

        const lexiAiDoc = await firestore.collection("lexiAi").doc("pricing").get();
        if (lexiAiDoc.exists) setLexiAiPricing(lexiAiDoc.data().prices || []);

        const lexiAiWebDoc = await firestore.collection("lexiAiWeb").doc("pricing").get();
        if (lexiAiWebDoc.exists) {
          setLexiAiWebPricing(lexiAiWebDoc.data().prices || []);
        }

        const subscriptionPlansDoc = await firestore.collection("subscriptionPlans").get();
        const fetchedPlans = subscriptionPlansDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubscriptionPlans(fetchedPlans);

        const pdfCreditsDoc = await firestore.collection("pdfCredits").doc("pricing").get();
        if (pdfCreditsDoc.exists) setPdfCredits(pdfCreditsDoc.data().credits || []);

        const addressPriceDoc = await firestore.collection('addressPrice').doc('price').get();
        if (addressPriceDoc.exists) setAddressPrice(addressPriceDoc.data().amount); // Fetch and set the amount

        const toolsPriceDoc = await firestore.collection("toolsPrice").get();
        const toolsPrices = toolsPriceDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setToolsPricing(toolsPrices);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPrices();
  }, []);

  useEffect(() => {
    const fetchLostAndFoundPrices = async () => {
      try {
        const lostAndFoundDoc = await firestore.collection("lostAndFound").doc("price").get();
        if (lostAndFoundDoc.exists) {
          const data = lostAndFoundDoc.data();
          setAddLostPrice(data.addLost || '');
          setChatLostPrice(data.chatLost || '');
          setChatFoundPrice(data.chatFound || '');
        }
      } catch (error) {
        console.error("Error fetching lost and found prices: ", error);
      }
    };
  
    fetchLostAndFoundPrices();
  }, []);

  // Save Opportunity Price as a number
  const handleSaveOpportunityPrice = async () => {
    try {
      await firestore.collection("opportunityPrice").doc("price").set({ amount: parseFloat(opportunityPrice) });
      setIsModalOpen(false);
      window.alert('Opportunity price updated successfully.');
    } catch (error) {
      console.error("Error updating opportunity price: ", error);
    }
  };

  // Save Event Price as a number
  const handleSaveEventPrice = async () => {
    try {
      await firestore.collection("eventPrice").doc("price").set({ amount: parseFloat(eventPrice) });
      setIsEventModalOpen(false);
      window.alert('Event price updated successfully.');
    } catch (error) {
      console.error("Error updating event price: ", error);
    }
  };

  // Save Lexi AI Price as a number
  const handleSaveLexiAiPrice = async () => {
    try {
      const updatedPricing = [...lexiAiPricing];
      if (editIndex >= 0) {
        // Editing an existing price
        updatedPricing[editIndex] = { price: parseFloat(lexiAiPrice), duration: lexiAiDuration };
        setEditIndex(-1);
      } else {
        // Adding a new price
        updatedPricing.push({ price: parseFloat(lexiAiPrice), duration: lexiAiDuration });
      }

      await firestore.collection("lexiAi").doc("pricing").set({
        prices: updatedPricing
      }, { merge: true });

      setLexiAiPricing(updatedPricing);
      setLexiAiPrice('');
      setLexiAiDuration('');
      setIsLexiAiModalOpen(false);
      window.alert('Lexi AI pricing updated successfully.');
    } catch (error) {
      console.error("Error updating Lexi AI pricing: ", error);
    }
  };

  // Save Tool Price as a number (with "All" option functionality)
  const handleSaveToolPrice = async () => {
    try {
      let updatedPricing = [...toolsPricing];

      if (selectedTool === "All") {
        // If "All" is selected, loop through all tools
        const newPrices = toolsList.map((tool) => ({ tool, price: parseFloat(toolPrice) }));
        const batch = firestore.batch();

        // Add new prices for each tool in the toolsList
        newPrices.forEach((toolItem) => {
          const docRef = firestore.collection("toolsPrice").doc(); // Create a new document
          batch.set(docRef, toolItem); // Add operation to the batch
        });

        await batch.commit(); // Commit all operations in the batch

        updatedPricing = [...updatedPricing, ...newPrices]; // Update the local state with the new prices
      } else if (editToolIndex >= 0) {
        // Editing an existing tool price
        updatedPricing[editToolIndex] = { tool: selectedTool, price: parseFloat(toolPrice) };
        const toolDocId = toolsPricing[editToolIndex].id;
        await firestore.collection("toolsPrice").doc(toolDocId).set({ tool: selectedTool, price: parseFloat(toolPrice) });
        setEditToolIndex(-1);
      } else {
        // Adding a new tool price for a specific tool
        const newTool = { tool: selectedTool, price: parseFloat(toolPrice) };
        const docRef = await firestore.collection("toolsPrice").add(newTool);
        updatedPricing.push({ id: docRef.id, ...newTool });
      }

      setToolsPricing(updatedPricing);
      setToolPrice("");
      setSelectedTool("");
      setIsToolModalOpen(false);
      window.alert("Tool price updated successfully.");
    } catch (error) {
      console.error("Error updating tool price: ", error);
    }
  };

  const handleEditLexiAiPrice = (index) => {
    setEditIndex(index);
    setLexiAiPrice(lexiAiPricing[index].price);
    setLexiAiDuration(lexiAiPricing[index].duration);
    setIsLexiAiModalOpen(true);
  };

  const handleEditToolPrice = (index) => {
    setEditToolIndex(index);
    setSelectedTool(toolsPricing[index].tool);
    setToolPrice(toolsPricing[index].price);
    setIsToolModalOpen(true);
  };

  const handleDeleteLexiAiPrice = async (index) => {
    try {
      const updatedPricing = lexiAiPricing.filter((_, i) => i !== index);
      await firestore.collection("lexiAi").doc("pricing").set({
        prices: updatedPricing
      }, { merge: true });
      setLexiAiPricing(updatedPricing);
      window.alert('Lexi AI pricing deleted successfully.');
    } catch (error) {
      console.error("Error deleting Lexi AI pricing: ", error);
    }
  };

  const handleDeleteToolPrice = async (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this tool price?");
    if (confirmDelete) {
      try {
        const toolDocId = toolsPricing[index].id;
        await firestore.collection("toolsPrice").doc(toolDocId).delete();
        const updatedPricing = toolsPricing.filter((_, i) => i !== index);
        setToolsPricing(updatedPricing);
        window.alert("Tool price deleted successfully.");
      } catch (error) {
        console.error("Error deleting tool price: ", error);
      }
    }
  };

  const handleDeleteOpportunityPrice = async () => {
    try {
      await firestore.collection("opportunityPrice").doc("price").delete();
      setOpportunityPrice('');
      window.alert('Opportunity price deleted successfully.');
    } catch (error) {
      console.error("Error deleting opportunity price: ", error);
    }
  };

  const handleDeleteEventPrice = async () => {
    try {
      await firestore.collection("eventPrice").doc("price").delete();
      setEventPrice('');
      window.alert('Event price deleted successfully.');
    } catch (error) {
      console.error("Error deleting event price: ", error);
    }
  };

  // Save Address Price as a number
const handleSaveAddressPrice = async () => {
  try {
    await firestore.collection("addressPrice").doc("price").set({ amount: parseFloat(addressPrice) });
    setIsAddressModalOpen(false);
    window.alert('Address view price updated successfully.');
  } catch (error) {
    console.error("Error updating address view price: ", error);
  }
};

// Delete Address Price
const handleDeleteAddressPrice = async () => {
  try {
    await firestore.collection("addressPrice").doc("price").delete();
    setAddressPrice('');
    window.alert('Address view price deleted successfully.');
  } catch (error) {
    console.error("Error deleting address view price: ", error);
  }
};

const formatPrice = (value) => (value === "Unlimited" ? "Unlimited" : `₹${value || 0}`);

const formatSubscriptionDetails = (details) => {
  if (!details) return "No details available";

  return `
    Users: ${details.userCount || 0}
    Tokens: ${details.tokens || "0"}
    Credits: ${details.credits || "0"}
    Access: ${details.access || "N/A"}
    Pricing:
      ${details.pricingByMonth
        .map((pricing) => `- ${pricing.month}: ${formatPrice(pricing.price)}`)
        .join("\n      ")}
    Additional credits:
      ${details.additionalCredits
        ? details.additionalCredits
            .map((credit) => `- ${credit.name} (${credit.count}): ${formatPrice(credit.price)}`)
            .join("\n      ")
        : "- No additional credits available"}
    Additional tokens:
      ${details.additionalTokens
        ? details.additionalTokens
            .map((token) => `- ${token.name} (${token.count}): ${formatPrice(token.price)}`)
            .join("\n      ")
        : "- No additional tokens available"}
  `;
};





const handleSaveSubscriptionPlan = async () => {
  try {
    const updatedPlans = [...subscriptionPlans];

    const planDetails = {
      userCount: parseInt(subscriptionDetails.userCount || 0, 10),
      tokens: subscriptionDetails.tokens === "Unlimited" ? "Unlimited" : parseInt(subscriptionDetails.tokens || 0, 10),
      credits: subscriptionDetails.credits === "Unlimited" ? "Unlimited" : parseInt(subscriptionDetails.credits || 0, 10),
      access: subscriptionDetails.access || "",
      pricingByMonth: subscriptionDetails.pricingByMonth.map((pricing) => ({
        month: pricing.month,
        price: parseFloat(pricing.price || 0),
      })),
      additionalCredits: subscriptionDetails.additionalCredits.map((credit) => ({
        name: credit.name || "",
        count: credit.count === "Unlimited" ? "Unlimited" : parseInt(credit.count || 0, 10),
        price: parseFloat(credit.price || 0),
      })),
      additionalTokens: subscriptionDetails.additionalTokens.map((token) => ({
        name: token.name || "",
        count: token.count === "Unlimited" ? "Unlimited" : parseInt(token.count || 0, 10),
        price: parseFloat(token.price || 0),
      })),
    };

    if (editSubscriptionIndex >= 0) {
      const docId = subscriptionPlans[editSubscriptionIndex].id;
      await firestore.collection("subscriptionPlans").doc(docId).set({
        name: subscriptionName,
        details: planDetails,
        description: subscriptionDetails.description,
      });
      updatedPlans[editSubscriptionIndex] = { id: docId, name: subscriptionName, details: planDetails };
      setEditSubscriptionIndex(-1);
    } else {
      const docRef = await firestore.collection("subscriptionPlans").add({
        name: subscriptionName,
        details: planDetails,
        description: subscriptionDetails.description,
      });
      updatedPlans.push({ id: docRef.id, name: subscriptionName, details: planDetails });
    }

    setSubscriptionPlans(updatedPlans);
    setSubscriptionName('');
    setSubscriptionDetails({
      description: "",
      userCount: "",
      tokens: "",
      credits: "",
      access: "",
      pricingByMonth: [],
      additionalCredits: [],
      additionalTokens: [],
    });
    setIsSubscriptionModalOpen(false);
    window.alert('Subscription Plan saved successfully.');
  } catch (error) {
    console.error("Error saving subscription plan:", error);
  }
};










const handleEditSubscriptionPlan = (index) => {
  setEditSubscriptionIndex(index);
  setSubscriptionName(subscriptionPlans[index].name);
  setSubscriptionDetails({
    ...subscriptionPlans[index].details,
    description: subscriptionPlans[index].description || "", // Ensure description is included
  });
  setIsSubscriptionModalOpen(true);
};


const handleDeleteSubscriptionPlan = async (index) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this subscription plan?");
  if (confirmDelete) {
    try {
      const planDocId = subscriptionPlans[index].id;
      await firestore.collection("subscriptionPlans").doc(planDocId).delete();
      const updatedPlans = subscriptionPlans.filter((_, i) => i !== index);
      setSubscriptionPlans(updatedPlans);
      window.alert("Subscription plan deleted successfully.");
    } catch (error) {
      console.error("Error deleting subscription plan: ", error);
    }
  }
};


const handleSavePdfCredit = async () => {
  try {
    const updatedCredits = [...pdfCredits];
    if (editPdfCreditIndex >= 0) {
      // Editing an existing credit
      updatedCredits[editPdfCreditIndex] = {
        name: pdfCreditName,
        count: parseInt(pdfCreditCount, 10),
        price: parseFloat(pdfCreditPrice),
      };
      setEditPdfCreditIndex(-1);
    } else {
      // Adding a new credit
      updatedCredits.push({
        name: pdfCreditName,
        count: parseInt(pdfCreditCount, 10),
        price: parseFloat(pdfCreditPrice),
      });
    }

    await firestore.collection("pdfCredits").doc("pricing").set({
      credits: updatedCredits,
    });

    setPdfCredits(updatedCredits);
    setPdfCreditName('');
    setPdfCreditCount('');
    setPdfCreditPrice('');
    setIsPdfCreditsModalOpen(false);
    window.alert('PDF Credits updated successfully.');
  } catch (error) {
    console.error("Error updating PDF Credits:", error);
  }
};



const handleEditPdfCredit = (index) => {
  setEditPdfCreditIndex(index);
  setPdfCreditName(pdfCredits[index].name);
  setPdfCreditCount(pdfCredits[index].count);
  setPdfCreditPrice(pdfCredits[index].price);
  setIsPdfCreditsModalOpen(true);
};


const handleDeletePdfCredit = async (index) => {
  try {
    const updatedCredits = pdfCredits.filter((_, i) => i !== index);
    await firestore.collection("pdfCredits").doc("pricing").set({
      credits: updatedCredits,
    });
    setPdfCredits(updatedCredits);
    window.alert('PDF Credit deleted successfully.');
  } catch (error) {
    console.error("Error deleting PDF Credit:", error);
  }
};


const handleSaveLexiAiWebPrice = async () => {
  try {
    const updatedPricing = [...lexiAiWebPricing];
    if (editLexiAiWebIndex >= 0) {
      updatedPricing[editLexiAiWebIndex] = {
        price: parseFloat(lexiAiWebPrice),
        duration: lexiAiWebDuration,
        credits: parseInt(lexiAiWebCredits, 10),
      };
      setEditLexiAiWebIndex(-1);
    } else {
      updatedPricing.push({
        price: parseFloat(lexiAiWebPrice),
        duration: lexiAiWebDuration,
        credits: parseInt(lexiAiWebCredits, 10),
      });
    }

    await firestore.collection("lexiAiWeb").doc("pricing").set({
      prices: updatedPricing,
    }, { merge: true });

    setLexiAiWebPricing(updatedPricing);
    setLexiAiWebPrice("");
    setLexiAiWebDuration("");
    setLexiAiWebCredits("");
    setIsLexiAiWebModalOpen(false);
    window.alert("LexiAI Web pricing updated successfully.");
  } catch (error) {
    console.error("Error updating LexiAI Web pricing:", error);
  }
};

const handleEditLexiAiWebPrice = (index) => {
  setEditLexiAiWebIndex(index);
  setLexiAiWebPrice(lexiAiWebPricing[index].price);
  setLexiAiWebDuration(lexiAiWebPricing[index].duration);
  setLexiAiWebCredits(lexiAiWebPricing[index].credits);
  setIsLexiAiWebModalOpen(true);
};

const handleDeleteLexiAiWebPrice = async (index) => {
  try {
    const updatedPricing = lexiAiWebPricing.filter((_, i) => i !== index);
    await firestore.collection("lexiAiWeb").doc("pricing").set({
      prices: updatedPricing,
    }, { merge: true });
    setLexiAiWebPricing(updatedPricing);
    window.alert("LexiAI Web pricing deleted successfully.");
  } catch (error) {
    console.error("Error deleting LexiAI Web pricing:", error);
  }
};

// Handlers to save prices
const handleSaveLostAndFoundPrices = async () => {
  try {
    await firestore.collection("lostAndFound").doc("price").set({
      addLost: parseFloat(addLostPrice),
      chatLost: parseFloat(chatLostPrice),
      chatFound: parseFloat(chatFoundPrice),
    });
    setIsLostAndFoundModalOpen(false);
    window.alert('Lost and Found pricing updated successfully.');
  } catch (error) {
    console.error("Error updating lost and found pricing: ", error);
  }
};

// Handler to delete prices
const handleDeleteLostAndFoundPrices = async (field) => {
  try {
    await firestore.collection("lostAndFound").doc("price").update({
      [field]: firebase.firestore.FieldValue.delete(),
    });

    if (field === "addLost") setAddLostPrice('');
    if (field === "chatLost") setChatLostPrice('');
    if (field === "chatFound") setChatFoundPrice('');

    window.alert(`${field} pricing deleted successfully.`);
  } catch (error) {
    console.error(`Error deleting ${field} pricing: `, error);
  }
};

  return (
    <div className="payments-container">
      <div className="sub-tabs">
        {["Events", "Opportunity", "Lexi AI", "Lexi AI Web", "PDF Credits", "Tools", "Address View Price", "Lost And Found" 
        // ,"Subscription Plans"
      ].map(tab => (
          <div
            key={tab}
            className={`sub-tab ${selectedSubTab === tab ? "active" : ""}`}
            onClick={() => setSelectedSubTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className="sub-tab-content">
        {selectedSubTab === "Opportunity" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsModalOpen(true)}>Set Opportunity Price</button>
            </div>
            {opportunityPrice && (
              <div className="opportunity-price">
                <span>Current Opportunity Price: ₹{opportunityPrice}</span>
                <div>
                  <button className="button button-edit" onClick={() => setIsModalOpen(true)}>Edit</button>
                  <button className="button button-delete" onClick={handleDeleteOpportunityPrice}>Delete</button>
                </div>
              </div>
            )}
            {isModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Opportunity Price</h3>
                  <input
                    type="text"
                    value={opportunityPrice}
                    onChange={(e) => setOpportunityPrice(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveOpportunityPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedSubTab === "Events" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsEventModalOpen(true)}>Set Event Price</button>
            </div>
            {eventPrice && (
              <div className="opportunity-price">
                <span>Current Event Price: ₹{eventPrice}</span>
                <div>
                  <button className="button button-edit" onClick={() => setIsEventModalOpen(true)}>Edit</button>
                  <button className="button button-delete" onClick={handleDeleteEventPrice}>Delete</button>
                </div>
              </div>
            )}
            {isEventModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Event Price</h3>
                  <input
                    type="text"
                    value={eventPrice}
                    onChange={(e) => setEventPrice(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveEventPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsEventModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedSubTab === "Lexi AI" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsLexiAiModalOpen(true)}>Set Lexi AI Price</button>
            </div>
            {lexiAiPricing.map((item, index) => (
              <div key={index} className="opportunity-price">
                <span>Price: {item.price}, Duration: {item.duration}</span>
                <div>
                  <button className="button button-edit" onClick={() => handleEditLexiAiPrice(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteLexiAiPrice(index)}>Delete</button>
                </div>
              </div>
            ))}
            {isLexiAiModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Lexi AI Price and Duration</h3>
                  <input
                    type="text"
                    placeholder="Price in coins"
                    value={lexiAiPrice}
                    onChange={(e) => setLexiAiPrice(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Duration (e.g., 3 Months)"
                    value={lexiAiDuration}
                    onChange={(e) => setLexiAiDuration(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveLexiAiPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsLexiAiModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

{selectedSubTab === "Lexi AI Web" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsLexiAiWebModalOpen(true)}>Set Lexi AI Web Price</button>
            </div>
            {lexiAiWebPricing.map((item, index) => (
              <div key={index} className="opportunity-price">
                <span>Price: ₹{item.price}, Duration: {item.duration}, Credits: {item.credits}</span>
                <div>
                  <button className="button button-edit" onClick={() => handleEditLexiAiWebPrice(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteLexiAiWebPrice(index)}>Delete</button>
                </div>
              </div>
            ))}
            {isLexiAiWebModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>Set Lexi AI Web Price, Duration, and Credits</h3>
                  <input
                    type="text"
                    placeholder="Price in coins"
                    value={lexiAiWebPrice}
                    onChange={(e) => setLexiAiWebPrice(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Duration (e.g., 3 Months)"
                    value={lexiAiWebDuration}
                    onChange={(e) => setLexiAiWebDuration(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Credits"
                    value={lexiAiWebCredits}
                    onChange={(e) => setLexiAiWebCredits(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveLexiAiWebPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsLexiAiWebModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

{selectedSubTab === "PDF Credits" && (
  <div>
    <div>
      <button className="button" onClick={() => setIsPdfCreditsModalOpen(true)}>Add PDF Credits</button>
    </div>
    {pdfCredits.map((credit, index) => (
      <div key={index} className="opportunity-price">
        <span>Pack: {credit.name}, Count: {credit.count}, Price: ₹{credit.price}</span>
        <div>
          <button className="button button-edit" onClick={() => handleEditPdfCredit(index)}>Edit</button>
          <button className="button button-delete" onClick={() => handleDeletePdfCredit(index)}>Delete</button>
        </div>
      </div>
    ))}
   {isPdfCreditsModalOpen && (
  <div className="modal-payment">
    <div className="modal-content-payment">
      <h3>{editPdfCreditIndex >= 0 ? "Edit PDF Credit" : "Add PDF Credit"}</h3>
      <input
        type="text"
        placeholder="Credit Pack Name"
        value={pdfCreditName}
        onChange={(e) => setPdfCreditName(e.target.value)}
      />
      <input
        type="number"
        placeholder="Credits Count"
        value={pdfCreditCount}
        onChange={(e) => setPdfCreditCount(e.target.value)}
      />
      <input
        type="number"
        placeholder="Price in ₹"
        value={pdfCreditPrice}
        onChange={(e) => setPdfCreditPrice(e.target.value)}
      />
      <div className="modal-buttons">
        <button className="button button-save" onClick={handleSavePdfCredit}>Save</button>
        <button className="button button-cancel" onClick={() => setIsPdfCreditsModalOpen(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}
  </div>
)}


        {selectedSubTab === "Tools" && (
          <div>
            <div>
              <button className="button" onClick={() => setIsToolModalOpen(true)}>Add Tool Price</button>
            </div>
            {toolsPricing.map((item, index) => (
              <div key={index} className="opportunity-price">
                <span>Tool: {item.tool}, Price: ₹{item.price}</span>
                <div>
                  <button className="button button-edit" onClick={() => handleEditToolPrice(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteToolPrice(index)}>Delete</button>
                </div>
              </div>
            ))}
            {isToolModalOpen && (
              <div className="modal-payment">
                <div className="modal-content-payment">
                  <h3>{editToolIndex >= 0 ? "Edit Tool Price" : "Add Tool Price"}</h3>
                  <select
                    disabled={editToolIndex >= 0}
                    value={selectedTool}
                    onChange={(e) => setSelectedTool(e.target.value)}
                  >
                    <option value="">Select Tool</option>
                    <option value="All">All</option> {/* Add option to select all tools */}
                    {toolsList.map((tool) => (
                      <option key={tool} value={tool}>{tool}</option>
                    ))}
                  </select>
                  <input
                    type="text"
                    placeholder="Price in ₹"
                    value={toolPrice}
                    onChange={(e) => setToolPrice(e.target.value)}
                  />
                  <div className="modal-buttons">
                    <button className="button button-save" onClick={handleSaveToolPrice}>Save</button>
                    <button className="button button-cancel" onClick={() => setIsToolModalOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

{selectedSubTab === "Address View Price" && (
  <div>
    <div>
      <button className="button" onClick={() => setIsAddressModalOpen(true)}>Set Address View Price</button>
    </div>
    {addressPrice && (
      <div className="opportunity-price">
        <span>Current Address View Price: ₹{addressPrice}</span>
        <div>
          <button className="button button-edit" onClick={() => setIsAddressModalOpen(true)}>Edit</button>
          <button className="button button-delete" onClick={handleDeleteAddressPrice}>Delete</button>
        </div>
      </div>
    )}
    {isAddressModalOpen && (
      <div className="modal-payment">
        <div className="modal-content-payment">
          <h3>Set Address View Price</h3>
          <input
            type="text"
            value={addressPrice}
            onChange={(e) => setAddressPrice(e.target.value)}
          />
          <div className="modal-buttons">
            <button className="button button-save" onClick={handleSaveAddressPrice}>Save</button>
            <button className="button button-cancel" onClick={() => setIsAddressModalOpen(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}
  </div>
)}

{selectedSubTab === "Lost And Found" && (
  <div>
    <div>
      <button className="button" onClick={() => setIsLostAndFoundModalOpen(true)}>Set Lost and Found Prices</button>
    </div>
    <div className="opportunity-price">
      <span>Add Lost Price: ₹{addLostPrice || 'Not set'}</span>
      <div>
        <button className="button button-edit" onClick={() => setIsLostAndFoundModalOpen(true)}>Edit</button>
        <button className="button button-delete" onClick={() => handleDeleteLostAndFoundPrices('addLost')}>Delete</button>
      </div>
    </div>
    <div className="opportunity-price">
      <span>Chat Lost Price: ₹{chatLostPrice || 'Not set'}</span>
      <div>
        <button className="button button-edit" onClick={() => setIsLostAndFoundModalOpen(true)}>Edit</button>
        <button className="button button-delete" onClick={() => handleDeleteLostAndFoundPrices('chatLost')}>Delete</button>
      </div>
    </div>
    <div className="opportunity-price">
      <span>Chat Found Price: ₹{chatFoundPrice || 'Not set'}</span>
      <div>
        <button className="button button-edit" onClick={() => setIsLostAndFoundModalOpen(true)}>Edit</button>
        <button className="button button-delete" onClick={() => handleDeleteLostAndFoundPrices('chatFound')}>Delete</button>
      </div>
    </div>
    {isLostAndFoundModalOpen && (
      <div className="modal-payment">
        <div className="modal-content-payment">
          <h3>Set Lost and Found Prices</h3>
          <input
            type="text"
            placeholder="Add Lost Price (₹)"
            value={addLostPrice}
            onChange={(e) => setAddLostPrice(e.target.value)}
          />
          <input
            type="text"
            placeholder="Chat Lost Price (₹)"
            value={chatLostPrice}
            onChange={(e) => setChatLostPrice(e.target.value)}
          />
          <input
            type="text"
            placeholder="Chat Found Price (₹)"
            value={chatFoundPrice}
            onChange={(e) => setChatFoundPrice(e.target.value)}
          />
          <div className="modal-buttons">
            <button className="button button-save" onClick={handleSaveLostAndFoundPrices}>Save</button>
            <button className="button button-cancel" onClick={() => setIsLostAndFoundModalOpen(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}
  </div>
)}

{selectedSubTab === "Subscription Plans" && (
  <div>
    <div style={{marginBottom: 20}}>
      <button className="button" onClick={() => setIsSubscriptionModalOpen(true)}>Add Subscription Plan</button>
    </div>
    {subscriptionPlans.map((item, index) => (
      <div key={index} className="opportunity-price" style={{borderBottom: '1px solid #ccc', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0, padding: 20}}>
        <span>
          <strong>Name:</strong> {item.name} <br />
          <div style={{marginTop: 15}}>
          <strong>Description:</strong>
          <p style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", marginTop: 0 }}>
            {item.description || "No description provided"}
          </p>
          </div>
          <strong>Details:</strong> 
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", marginTop: 0 }}>
          {formatSubscriptionDetails(item.details)}
        </pre>
        </span>
        <div>
          <button className="button button-edit" onClick={() => handleEditSubscriptionPlan(index)}>Edit</button>
          <button className="button button-delete" onClick={() => handleDeleteSubscriptionPlan(index)}>Delete</button>
        </div>
      </div>
    ))}
    {isSubscriptionModalOpen && (
      <div className="modal-payment">
        <div className="modal-content-payment" style={{
        maxHeight: "80vh", // Limits the height to 80% of the viewport
        overflowY: "auto", // Adds vertical scrolling
        padding: "20px", // Adds padding for better spacing
      }}>
          <h3>{editSubscriptionIndex >= 0 ? "Edit Subscription Plan" : "Add Subscription Plan"}</h3>
          <input
            type="text"
            placeholder="Plan Name (e.g., Gold)"
            value={subscriptionName}
            onChange={(e) => setSubscriptionName(e.target.value)}
          />
          <textarea
          style={{width: '90%', height: 100}}
            placeholder="Plan Details"
            value={subscriptionDetails}
            onChange={(e) => setSubscriptionDetails(e.target.value)}
          ></textarea>
          <h4>General Details</h4>
          <div>
          <label>Description:</label>
            <textarea
              style={{ width: "90%", height: "80px" }}
              placeholder="Enter a description for the plan"
              value={subscriptionDetails.description || ""}
              onChange={(e) =>
                setSubscriptionDetails((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
            <label>Number of Users:</label>
            <input
              type="number"
              placeholder="1"
              value={subscriptionDetails.userCount || ""}
              onChange={(e) =>
                setSubscriptionDetails((prev) => ({
                  ...prev,
                  userCount: e.target.value, // Value is captured as a string initially
                }))
              }
            />
          </div>
          <div>
  <label>Number of Tokens:</label>
  <input
    type="text" // Change input type to text to allow "Unlimited"
    placeholder="100,000 or Unlimited"
    value={subscriptionDetails.tokens || ""}
    onChange={(e) =>
      setSubscriptionDetails((prev) => ({
        ...prev,
        tokens: e.target.value, // Accept both text and numeric input
      }))
    }
  />
</div>
<div>
  <label>Free Credits:</label>
  <input
    type="text" // Change input type to text to allow "Unlimited"
    placeholder="200 or Unlimited"
    value={subscriptionDetails.credits || ""}
    onChange={(e) =>
      setSubscriptionDetails((prev) => ({
        ...prev,
        credits: e.target.value, // Accept both text and numeric input
      }))
    }
  />
</div>
          <div>
            <label>Access Details:</label>
            <input
              type="text"
              placeholder="Access to mobile app"
              value={subscriptionDetails.access}
              onChange={(e) =>
                setSubscriptionDetails((prev) => ({
                  ...prev,
                  access: e.target.value,
                }))
              }
            />
          </div>
          <h4>Pricing By Month</h4>
{subscriptionDetails.pricingByMonth.map((pricing, index) => (
  <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
    <input
      type="text"
      placeholder="Enter Month (e.g., 1 Month)"
      value={pricing.month || ""}
      onChange={(e) => {
        const updatedPricing = [...subscriptionDetails.pricingByMonth];
        updatedPricing[index].month = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, pricingByMonth: updatedPricing }));
      }}
      style={{ marginRight: 10, marginBottom: 10 }}
    />
    <input
      type="number"
      placeholder="Enter Price (₹)"
      value={pricing.price || ""}
      onChange={(e) => {
        const updatedPricing = [...subscriptionDetails.pricingByMonth];
        updatedPricing[index].price = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, pricingByMonth: updatedPricing }));
      }}
      style={{ marginRight: 10, marginBottom: 10 }}
    />
    <button
      onClick={() => {
        const updatedPricing = subscriptionDetails.pricingByMonth.filter((_, i) => i !== index);
        setSubscriptionDetails((prev) => ({ ...prev, pricingByMonth: updatedPricing }));
      }}
      style={{ marginLeft: 10, marginTop: 0 }}
    >
      Remove
    </button>
  </div>
))}
<button
style={{ marginTop: 0, marginBottom: 20 }}
  onClick={() =>
    setSubscriptionDetails((prev) => ({
      ...prev,
      pricingByMonth: [...prev.pricingByMonth, { month: "", price: "" }],
    }))
  }
>
  Add Pricing By Month
</button>

          <h4>Additional Credits</h4>
{subscriptionDetails.additionalCredits.map((credit, index) => (
  <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
    <input
      type="text"
      placeholder="Credit Pack Name"
      value={credit.name || ""}
      onChange={(e) => {
        const updatedCredits = [...subscriptionDetails.additionalCredits];
        updatedCredits[index].name = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, additionalCredits: updatedCredits }));
      }}
      style={{ marginRight: 10, marginBottom: 10 }}
    />
    <input
    
      type="number"
      placeholder="Credit Count"
      value={credit.count || ""}
      onChange={(e) => {
        const updatedCredits = [...subscriptionDetails.additionalCredits];
        updatedCredits[index].count = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, additionalCredits: updatedCredits }));
      }}
      style={{ marginRight: 10, marginBottom: 10 }}
    />
    <input
    style={{marginBottom: 10}}
      type="number"
      placeholder="Price (₹)"
      value={credit.price || ""}
      onChange={(e) => {
        const updatedCredits = [...subscriptionDetails.additionalCredits];
        updatedCredits[index].price = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, additionalCredits: updatedCredits }));
      }}
    />
    <button
      onClick={() => {
        const updatedCredits = subscriptionDetails.additionalCredits.filter((_, i) => i !== index);
        setSubscriptionDetails((prev) => ({ ...prev, additionalCredits: updatedCredits }));
      }}
      style={{ marginLeft: 10, marginTop: 0 }}
    >
      Remove
    </button>
  </div>
))}
<button
style={{marginTop: 0, marginBottom: 20}}
  onClick={() =>
    setSubscriptionDetails((prev) => ({
      ...prev,
      additionalCredits: [...prev.additionalCredits, { name: "", count: "", price: "" }],
    }))
  }
>
  Add Credit
</button>

<h4>Additional Tokens</h4>
{subscriptionDetails.additionalTokens.map((token, index) => (
  <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
    <input
      type="text"
      placeholder="Token Pack Name"
      value={token.name || ""}
      onChange={(e) => {
        const updatedTokens = [...subscriptionDetails.additionalTokens];
        updatedTokens[index].name = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, additionalTokens: updatedTokens }));
      }}
      style={{ marginRight: 10, marginBottom: 10 }}
    />
    <input
      type="text" // Changed to text to allow "Unlimited"
      placeholder="Token Count"
      value={token.count || ""}
      onChange={(e) => {
        const updatedTokens = [...subscriptionDetails.additionalTokens];
        updatedTokens[index].count = e.target.value; // Accept any text input
        setSubscriptionDetails((prev) => ({ ...prev, additionalTokens: updatedTokens }));
      }}
      style={{ marginRight: 10, marginBottom: 10 }}
    />
    <input
      type="number"
      placeholder="Price (₹)"
      value={token.price || ""}
      onChange={(e) => {
        const updatedTokens = [...subscriptionDetails.additionalTokens];
        updatedTokens[index].price = e.target.value;
        setSubscriptionDetails((prev) => ({ ...prev, additionalTokens: updatedTokens }));
      }}
      style={{ marginBottom: 10 }}
    />
    <button
      onClick={() => {
        const updatedTokens = subscriptionDetails.additionalTokens.filter((_, i) => i !== index);
        setSubscriptionDetails((prev) => ({ ...prev, additionalTokens: updatedTokens }));
      }}
      style={{ marginLeft: 10, marginTop: 0 }}
    >
      Remove
    </button>
  </div>
))}
<button
style={{ marginTop: 0, marginBottom: 20 }}
  onClick={() =>
    setSubscriptionDetails((prev) => ({
      ...prev,
      additionalTokens: [...prev.additionalTokens, { name: "", count: "", price: "" }],
    }))
  }
>
  Add Token
</button>



          <div className="modal-buttons">
            <button className="button button-save" onClick={handleSaveSubscriptionPlan}>Save</button>
            <button className="button button-cancel" onClick={() => setIsSubscriptionModalOpen(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}
  </div>
)}




        
      </div>
    </div>
  );
};

export default Payments;
