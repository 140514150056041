import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Payments.css"; // Same style as the Payments page
import axios from 'axios';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { auth, db } from './firebaseConfig';

// Firebase configuration should already be initialized in your project
const firestore = firebase.firestore();

const API_KEY = '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'; // Replace with your actual API key

const Dataset = () => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [dataset, setDataset] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    // Fetch dataset from Firebase on component mount
    const fetchDataset = async () => {
      try {
        const datasetDoc = await firestore.collection("chatDataset").get();
        const datasetData = datasetDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDataset(datasetData);
      } catch (error) {
        console.error("Error fetching dataset: ", error);
      }
    };

    fetchDataset();
  }, []);

  // Save prompt and response in Firebase
  const handleSaveDataset = async () => {
    try {
      if (editIndex >= 0) {
        // Editing an existing prompt-response pair
        const updatedDataset = [...dataset];
        updatedDataset[editIndex] = { prompt, response };
        const datasetDocId = dataset[editIndex].id;
        await firestore.collection("chatDataset").doc(datasetDocId).set({ prompt, response });
        setDataset(updatedDataset);
        setEditIndex(-1);
      } else {
        // Adding a new prompt-response pair
        const newPromptResponse = { prompt, response };
        const docRef = await firestore.collection("chatDataset").add(newPromptResponse);
        setDataset([...dataset, { id: docRef.id, ...newPromptResponse }]);
      }
  
      // After saving, generate and store embeddings
      await storeDatasetEmbeddings();
  
      // Reset state after saving
      setPrompt('');
      setResponse('');
      setIsModalOpen(false);
      window.alert("Prompt-response pair added/updated successfully.");
    } catch (error) {
      console.error("Error saving dataset: ", error);
    }
  };
  

  // Edit existing prompt-response pair
  const handleEditDataset = (index) => {
    setEditIndex(index);
    setPrompt(dataset[index].prompt);
    setResponse(dataset[index].response);
    setIsModalOpen(true);
  };

  // Delete a prompt-response pair from Firebase
  const handleDeleteDataset = async (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this prompt-response pair?");
    if (confirmDelete) {
      try {
        const datasetDocId = dataset[index].id;
        await firestore.collection("chatDataset").doc(datasetDocId).delete();
        const updatedDataset = dataset.filter((_, i) => i !== index);
        setDataset(updatedDataset);
        window.alert("Prompt-response pair deleted successfully.");
      } catch (error) {
        console.error("Error deleting dataset: ", error);
      }
    }
  };

  const generateEmbedding = async (text) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/embeddings',
        {
          model: 'text-embedding-ada-002',
          input: text,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_KEY}`,  // Replace with your actual API key
          },
        }
      );
      
      const embedding = response.data.data[0].embedding;
  
      // Ensure the embedding is a valid array
      if (Array.isArray(embedding)) {
        return embedding;
      } else {
        throw new Error('Invalid embedding format');
      }
    } catch (error) {
      console.error('Error generating embedding:', error);
      return null;  // Return null if embedding generation fails
    }
  };
  
  
  // Example of generating and storing embeddings for dataset prompts
  const storeDatasetEmbeddings = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'chatDataset'));
  
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();
  
        // Check if the embedding already exists
        if (!data.embedding) {
          try {
            const embedding = await generateEmbedding(data.prompt);
  
            // Update Firestore with the embedding
            await updateDoc(doc.ref, { embedding });
            console.log(`Successfully stored embedding for prompt: ${data.prompt}`);
          } catch (error) {
            console.error(`Error generating embedding for prompt: ${data.prompt}`, error);
          }
        } else {
          console.log(`Embedding already exists for prompt: ${data.prompt}`);
        }
      });
    } catch (error) {
      console.error('Error fetching dataset for embedding generation:', error);
    }
  };
  
  // Call storeDatasetEmbeddings after adding a new prompt-response pair or editing one

  
  
  

  return (
    <div className="payments-container">
      <div className="sub-tabs">
        <div className="sub-tab active">Dataset Management</div>
      </div>

      <div className="sub-tab-content">
        <div>
          <button className="button" onClick={() => setIsModalOpen(true)}>Add Prompt-Response</button>
        </div>

        {/* Display existing dataset */}
        {dataset.map((item, index) => (
          <div key={index} className="opportunity-price" style={{flexDirection: 'column', alignItems: 'flex-start', gap: '15px', marginTop: '30px', backgroundColor: '#fff', padding: '20px'}}>
            <span>Prompt: {item.prompt}</span>
            <span>Response: {item.response}</span>
            <div style={{marginTop: '10px'}}>
              <button className="button button-edit" onClick={() => handleEditDataset(index)}>Edit</button>
              <button className="button button-delete" onClick={() => handleDeleteDataset(index)}>Delete</button>
            </div>
          </div>
        ))}

        {/* Modal to add or edit prompt-response */}
        {isModalOpen && (
          <div className="modal-payment">
            <div className="modal-content-payment">
              <h3>{editIndex >= 0 ? "Edit Prompt-Response" : "Add Prompt-Response"}</h3>
              <input
                type="text"
                placeholder="Enter prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <input
                type="text"
                placeholder="Enter response"
                value={response}
                onChange={(e) => setResponse(e.target.value)}
              />
              <div className="modal-buttons">
                <button className="button button-save" onClick={handleSaveDataset}>Save</button>
                <button className="button button-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dataset;
