import React from 'react';

const RejectionModal = ({ show, onClose, onConfirm, customMessage, onMessageChange }) => {
  if (!show) return null;

  const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '400px',
    maxWidth: '90%',
    padding: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
  };

  const headerStyles = {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#333',
  };

  const textareaStyles = {
    width: '95%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    resize: 'none',
    fontSize: '14px',
    marginBottom: '10px',
    outline: 'none',
  };

  const buttonContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
  };

  const buttonStyles = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#fff',
  };

  const confirmButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#500523',
  };

  const cancelButtonStyles = {
    ...buttonStyles,
    backgroundColor: '#888',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={headerStyles}>Rejection Confirmation</h2>
        <p style={{ marginBottom: '15px', fontSize: '14px', color: '#555' }}>
          Enter a custom rejection message:
        </p>
        <textarea
          value={customMessage}
          onChange={(e) => onMessageChange(e.target.value)}
          placeholder="Add a rejection message"
          rows={4}
          style={textareaStyles}
        />
        <div style={buttonContainerStyles}>
          <button onClick={onConfirm} style={confirmButtonStyles}>
            Confirm
          </button>
          <button onClick={onClose} style={cancelButtonStyles}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectionModal;
