import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Loader from "./Loader";
import "./UsersScreen.css"; // Reusing the UsersScreen CSS for consistent styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser, faCalendar, faPhone, faClock, faCheckCircle, faMoneyBillAlt, faSearch } from "@fortawesome/free-solid-svg-icons";

const WithdrawalRequests = () => {
  const [requestsByDate, setRequestsByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRequestsByDate, setFilteredRequestsByDate] = useState({});

  const groupRequestsByDate = (requests) => {
    return requests.reduce((groups, request) => {
      const date = request.timestamp ? request.timestamp.toDate().toLocaleDateString() : "No Date";
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(request);
      return groups;
    }, {});
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDoc = await firebase.firestore().collection("users").doc(userId).get();
      return userDoc.exists
        ? { 
            name: userDoc.data().name || "Unknown", 
            phoneNumber: userDoc.data().phoneNumber || "Not Provided", 
            email: userDoc.data().email || "Not Provided" 
          }
        : {};
    } catch (error) {
      console.error("Error fetching user details: ", error);
      return { name: "Unknown", phoneNumber: "Not Provided", email: "Not Provided" };
    }
  };

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        const snapshot = await firebase.firestore().collection("withdrawRequests").get();
        const requestsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const request = { id: doc.id, ...doc.data() };
  
            // Use userId from the request to fetch specific user details
            if (request.userId) {
              const userDetails = await fetchUserDetails(request.userId);
              request.name = userDetails.name;
              request.phoneNumber = userDetails.phoneNumber;
              request.email = userDetails.email;
            }
  
            return request;
          })
        );
  
        // Sort requests by timestamp in descending order
        const sortedRequests = requestsData.sort((a, b) => {
          const aTimestamp = a.timestamp ? a.timestamp.toMillis() : 0;
          const bTimestamp = b.timestamp ? b.timestamp.toMillis() : 0;
          return bTimestamp - aTimestamp;
        });
  
        const groupedRequests = groupRequestsByDate(sortedRequests);
        setRequestsByDate(groupedRequests);
        setFilteredRequestsByDate(groupedRequests);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching withdrawal requests: ", error);
        setLoading(false);
      }
    };
  
    fetchWithdrawalRequests();
  }, []);

  useEffect(() => {
    const filteredRequests = {};
    Object.keys(requestsByDate).forEach((date) => {
      const requests = requestsByDate[date].filter((request) =>
        request.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (requests.length) {
        filteredRequests[date] = requests;
      }
    });
    setFilteredRequestsByDate(filteredRequests);
  }, [searchTerm, requestsByDate]);

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Withdrawal Requests: {Object.keys(filteredRequestsByDate).reduce((total, date) => total + filteredRequestsByDate[date].length, 0)}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Requests..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="users-screen">
        <div className="users-container">
          {Object.keys(filteredRequestsByDate).map((date) => (
            <div key={date} className="date-group">
              <h3>{date} - {filteredRequestsByDate[date].length} Requests</h3>
              <div className="users-row">
                {filteredRequestsByDate[date].map((request) => (
                  <div key={request.id} className="user-card">
                    <div className="user-details">
                      <h2>{request.name}</h2>
                      <h4>Date: {request.timestamp
                            ? new Date(request.timestamp.seconds * 1000).toLocaleString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                  hour12: true
                              })
                            : 'Unknown'}</h4>
                      <p><FontAwesomeIcon icon={faPhone} /> {request.email}</p>
                      <p><FontAwesomeIcon icon={faPhone} /> {request.phoneNumber}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Amount: ₹{request.amount}</p>
                      <p><FontAwesomeIcon icon={faCheckCircle} /> Status: {request.status}</p>
                      <p><FontAwesomeIcon icon={faUser} /> UPI ID: {request.upiId}</p>
                      <p><FontAwesomeIcon icon={faClock} /> Type: {request.type}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WithdrawalRequests;
