import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from './firebaseConfig';
import "./Payments.css";
import { serverTimestamp, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faBriefcase, faBirthdayCake, faTrashAlt, faEdit, faMoneyBillAlt, faSearch, faCalendar } from "@fortawesome/free-solid-svg-icons";

const OfficialLinks = () => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [keyword, setKeyword] = useState('');
  const [section, setSection] = useState('');
  const [links, setLinks] = useState([]);
  const [forms, setForms] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormsModalOpen, setIsFormsModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [editIndex, setEditIndex] = useState(-1);
  const [selectedTab, setSelectedTab] = useState("links");
  const storage = getStorage();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLinks, setFilteredLinks] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);


  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const linksCollection = collection(db, "officialSites");
        const snapshot = await getDocs(linksCollection);
        const loadedLinks = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setLinks(loadedLinks);
      } catch (error) {
        console.error("Error fetching official links: ", error);
      }
    };

    const fetchForms = async () => {
      try {
        const formsCollection = collection(db, "forms");
        const snapshot = await getDocs(formsCollection);
        const loadedForms = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setForms(loadedForms);
      } catch (error) {
        console.error("Error fetching forms: ", error);
      }
    };

    fetchLinks();
    fetchForms();
  }, []);

  useEffect(() => {
    if (selectedTab === "links") {
      setFilteredLinks(
        links.filter((link) =>
          link.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          link.keyword?.some((k) => k.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    } else if (selectedTab === "forms") {
      setFilteredForms(
        forms.filter((form) =>
          form.title?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, links, forms, selectedTab]);
  

  const handleSaveLink = async () => {
    const keywordsArray = keyword.split(',').map(k => k.trim());
    try {
      if (editIndex >= 0 && selectedTab === "links") {
        const linkDocId = links[editIndex].id;
        const linkDocRef = doc(db, "officialSites", linkDocId);
        const linkDoc = await getDoc(linkDocRef);
        const existingData = linkDoc.data();

        await updateDoc(linkDocRef, {
          title,
          link,
          keyword: keywordsArray,
          createdAt: existingData.createdAt,
          updatedAt: serverTimestamp()
        });

        const updatedLinks = [...links];
        updatedLinks[editIndex] = { id: linkDocId, title, link, keyword: keywordsArray, section, createdAt: existingData.createdAt };
        setLinks(updatedLinks);
      } else if (editIndex >= 0 && selectedTab === "forms") {
        // Edit Form
        const formDocId = forms[editIndex].id;
        const formDocRef = doc(db, "forms", formDocId);
        const formDoc = await getDoc(formDocRef);
        const existingData = formDoc.data();

        await updateDoc(formDocRef, {
          title,
          createdAt: existingData.createdAt,
          updatedAt: serverTimestamp()
        });

        const updatedForms = [...forms];
        updatedForms[editIndex] = { id: formDocId, title, pdfUrl: existingData.pdfUrl, createdAt: existingData.createdAt };
        setForms(updatedForms);
      } else if (selectedTab === "links") {
        const newLink = {
          title,
          link,
          keyword: keywordsArray,
          createdAt: serverTimestamp()
        };
        const docRef = await addDoc(collection(db, "officialSites"), newLink);
        setLinks([...links, { id: docRef.id, ...newLink }]);
      } else if (selectedTab === "forms") {
        // Add a new form
        const pdfUrl = await uploadPdfFile();
        const newForm = {
          title,
          pdfUrl,
          createdAt: serverTimestamp()
        };
        const docRef = await addDoc(collection(db, "forms"), newForm);
        setForms([...forms, { id: docRef.id, ...newForm }]);
      }

      setTitle('');
      setLink('');
      setKeyword('');
      setPdfFile(null);
      setSection('');
      setIsModalOpen(false);
      setIsFormsModalOpen(false);
      alert("Link/Form added/updated successfully.");
    } catch (error) {
      console.error("Error saving link/form: ", error);
    }
  };

  

  const handleEditLink = (index) => {
    setEditIndex(index);
    if (selectedTab === "links") {
      setTitle(links[index].title);
      setLink(links[index].link);
      setKeyword(Array.isArray(links[index].keyword) ? links[index].keyword.join(', ') : links[index].keyword);
      setSection(links[index].section);
      setIsModalOpen(true);
    } else if (selectedTab === "forms") {
      setTitle(forms[index].title);
      setIsFormsModalOpen(true);
    }
  };

  // const handleDeleteLink = async (index) => {
  //   const confirmDelete = window.confirm(`Are you sure you want to delete this ${selectedTab === "links" ? "link" : "form"}?`);
  //   if (confirmDelete) {
  //     try {
  //       const docId = selectedTab === "links" ? links[index].id : forms[index].id;
  //       await deleteDoc(doc(db, selectedTab === "links" ? "officialSites" : "forms", docId));

  //       if (selectedTab === "links") {
  //         const updatedLinks = links.filter((_, i) => i !== index);
  //         setLinks(updatedLinks);
  //       } else {
  //         const updatedForms = forms.filter((_, i) => i !== index);
  //         setForms(updatedForms);
  //       }

  //       alert(`${selectedTab === "links" ? "Link" : "Form"} deleted successfully.`);
  //     } catch (error) {
  //       console.error(`Error deleting ${selectedTab === "links" ? "link" : "form"}: `, error);
  //     }
  //   }
  // };

  const handleDeleteLink = async (index) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete this ${selectedTab === "links" ? "link" : "form"}?`);
    if (confirmDelete) {
      try {
        const docId = selectedTab === "links" ? links[index].id : forms[index].id;
        const collectionName = selectedTab === "links" ? "officialSites" : "forms";
  
        await deleteDoc(doc(db, collectionName, docId));
        console.log(`${selectedTab === "links" ? "Link" : "Form"} with ID ${docId} deleted from Firebase.`);
  
        // Update the local state immediately to reflect UI change
        if (selectedTab === "links") {
          const updatedLinks = links.filter((_, i) => i !== index);
          setLinks(updatedLinks);  // Update links state
          setFilteredLinks(updatedLinks);  // Also update filtered list if it's shown
        } else {
          const updatedForms = forms.filter((_, i) => i !== index);
          setForms(updatedForms);  // Update forms state
          setFilteredForms(updatedForms);  // Also update filtered list if it's shown
        }
  
        alert(`${selectedTab === "links" ? "Link" : "Form"} deleted successfully.`);
      } catch (error) {
        console.error(`Error deleting ${selectedTab === "links" ? "link" : "form"}: `, error);
        alert(`Failed to delete the ${selectedTab === "links" ? "link" : "form"}. Please try again.`);
      }
    }
  };
  

  const uploadPdfFile = async () => {
    if (!pdfFile) return null;
    const storageRef = ref(storage, `forms/${pdfFile.name}`);
    const snapshot = await uploadBytes(storageRef, pdfFile);
    const downloadUrl = await getDownloadURL(snapshot.ref);
    return downloadUrl;
  };

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  return (
    <div className="payments-container">
          <div className="header">
        <div className="user-count">
          <p>Total {selectedTab === "links" ? "Links" : "Forms"}: {selectedTab === "links" ? filteredLinks.length : filteredForms.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder={`Search ${selectedTab === "links" ? "Links" : "Forms"}...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="sub-tabs" style={{marginTop: 50}}>
        <div className={`sub-tab ${selectedTab === "links" ? "active" : ""}`} onClick={() => setSelectedTab("links")}>
          Official Links Management
        </div>
        <div className={`sub-tab ${selectedTab === "forms" ? "active" : ""}`} onClick={() => setSelectedTab("forms")}>
          Forms Management
        </div>
      </div>

      <div className="sub-tab-content">
        {selectedTab === "links" && (
          <>
            <button className="button" onClick={() => setIsModalOpen(true)}>Add Official Link</button>
            {filteredLinks.map((item, index) => (
              <div key={index} className="opportunity-price" style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '15px', marginTop: '30px', backgroundColor: '#fff', padding: '20px' }}>
                <span><b>Title:</b> {item.title}</span>
                <span><b>Link:</b> <a href={item.link} target='_blank' style={{ color: 'blue', cursor: 'pointer' }}>{item.link}</a></span>
                <span><b>Search Keyword:</b> {Array.isArray(item.keyword) ? item.keyword.join(', ') : item.keyword}</span>
                <div style={{ marginTop: '10px' }}>
                  <button className="button button-edit" onClick={() => handleEditLink(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteLink(index)}>Delete</button>
                </div>
              </div>
            ))}
          </>
        )}

        {selectedTab === "forms" && (
          <>
            <button className="button" onClick={() => setIsFormsModalOpen(true)}>Add Form</button>
            {filteredForms.map((item, index) => (
              <div key={index} className="opportunity-price" style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '15px', marginTop: '30px', backgroundColor: '#fff', padding: '20px' }}>
                <span><b>Title:</b> {item.title}</span>
                <span><b>PDF:</b> <a href={item.pdfUrl} target='_blank' style={{ color: 'blue', cursor: 'pointer' }}>View</a></span>
                <div style={{ marginTop: '10px' }}>
                  <button className="button button-edit" onClick={() => handleEditLink(index)}>Edit</button>
                  <button className="button button-delete" onClick={() => handleDeleteLink(index)}>Delete</button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      {isModalOpen && (
        <div className="modal-payment">
          <div className="modal-content-payment">
            <h3>{editIndex >= 0 ? "Edit Official Link" : "Add Official Link"}</h3>
            <input type="text" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <input type="text" placeholder="Enter link" value={link} onChange={(e) => setLink(e.target.value)} />
            <input type="text" placeholder="Enter Search keyword (comma-separated)" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
            <div className="modal-buttons">
              <button className="button button-save" onClick={handleSaveLink}>Save</button>
              <button className="button button-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isFormsModalOpen && (
        <div className="modal-payment">
          <div className="modal-content-payment">
            <h3>{editIndex >= 0 ? "Edit Form" : "Add Form"}</h3>
            <input type="text" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <div style={{ marginBottom: '15px' }}>
              <label 
                htmlFor="pdfUpload" 
                style={{
                  display: 'block',
                  padding: '10px 15px',
                  margin: '0 15px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold'
                }}
              >
                Upload PDF
              </label>
              <input 
                type="file" 
                id="pdfUpload" 
                accept="application/pdf" 
                onChange={handleFileChange} 
                style={{ 
                  display: 'none' // Hide the default file input
                }} 
              />
              {pdfFile && (
              <div 
                style={{ 
                  marginTop: '10px', 
                  wordWrap: 'break-word', 
                  maxWidth: '100%', 
                  backgroundColor: '#f8f9fa', 
                  padding: '10px', 
                  borderRadius: '5px', 
                  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' 
                }}
              >
                <strong>Selected File:</strong> {pdfFile.name}
              </div>
            )}
            </div>
            <div className="modal-buttons">
              <button className="button button-save" onClick={handleSaveLink}>Save</button>
              <button className="button button-cancel" onClick={() => setIsFormsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfficialLinks;
