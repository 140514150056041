import React, { useState } from "react";
import "./Payments.css"; // For consistent styling
import LawyersScreen from "./LawyersScreen";
import AuditorsScreen from "./AuditorsScreen";
import LawFirmsScreen from "./LawFirmsScreen";
import AuditFirmsScreen from "./AuditFirmsScreen";
import ConsultantsScreen from "./ConsultantsScreen";
import PrivateDetectivesScreen from "./PrivateDetectivesScreen";
import LexshieldScreen from "./LexshieldScreen";

const Enrollments = () => {
  const [activeTab, setActiveTab] = useState("lawyers");

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "lawyers":
        return <LawyersScreen />;
      case "auditors":
        return <AuditorsScreen />;
      case "lawfirms":
        return <LawFirmsScreen />;
      case "auditfirms":
        return <AuditFirmsScreen />;
      case "consultants":
        return <ConsultantsScreen />;
      case "privatedetectives":
        return <PrivateDetectivesScreen />;
      case "Lexshield":
        return <LexshieldScreen />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="sub-tabs">
        <div
          className={`sub-tab ${activeTab === "lawyers" ? "active" : ""}`}
          onClick={() => setActiveTab("lawyers")}
        >
          Lawyers
        </div>
        <div
          className={`sub-tab ${activeTab === "auditors" ? "active" : ""}`}
          onClick={() => setActiveTab("auditors")}
        >
          Auditors
        </div>
        <div
          className={`sub-tab ${activeTab === "lawfirms" ? "active" : ""}`}
          onClick={() => setActiveTab("lawfirms")}
        >
          Law Firms
        </div>
        <div
          className={`sub-tab ${activeTab === "auditfirms" ? "active" : ""}`}
          onClick={() => setActiveTab("auditfirms")}
        >
          Audit Firms
        </div>
        <div
          className={`sub-tab ${activeTab === "consultants" ? "active" : ""}`}
          onClick={() => setActiveTab("consultants")}
        >
          Consultants
        </div>
        <div
          className={`sub-tab ${activeTab === "privatedetectives" ? "active" : ""}`}
          onClick={() => setActiveTab("privatedetectives")}
        >
          Private Detectives
        </div>
        <div
          className={`sub-tab ${activeTab === "Lexshield" ? "active" : ""}`}
          onClick={() => setActiveTab("Lexshield")}
        >
          Lex Shield
        </div>
      </div>

      <div className="sub-tab-content">{renderActiveTabContent()}</div>
    </div>
  );
};

export default Enrollments;
