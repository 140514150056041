import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const EventsStudentDetails = () => {
  const [studentRegistrations, setStudentRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    student: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    const fetchStudentRegistrations = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("studentRegistrations")
          .orderBy("eventDate", "desc")
          .get();
        const students = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = students.map((student) => student.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setStudentRegistrations(students);
        setFilteredStudents(students);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching student registrations: ", error);
        setLoading(false);
      }
    };

    fetchStudentRegistrations();
  }, []);

  useEffect(() => {
    setFilteredStudents(
      studentRegistrations.filter((student) => {
        const userDetail = userDetails[student.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.StudentName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.StudentEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.StudentMobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.eventTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.eventOrganizer?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [searchTerm, studentRegistrations, userDetails]);

  const handleDelete = (student) => {
    setModalState({ show: true, student });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("studentRegistrations")
        .doc(modalState.student.id)
        .delete();
      setStudentRegistrations((prev) =>
        prev.filter((item) => item.id !== modalState.student.id)
      );
      setFilteredStudents((prev) =>
        prev.filter((item) => item.id !== modalState.student.id)
      );
      setModalState({ show: false, student: null });
    } catch (error) {
      console.error("Error deleting student registration: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, student: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Students: {filteredStudents.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Students..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredStudents.map((student) => {
          const userDetail = userDetails[student.userId] || {};
          return (
            <div key={student.id} className="contentItemEvents">
              <div className="user-info-container">
                <img
                  src={userDetail.profileImage || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="name label detail">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Student Name:</p>
                <p className="detail">{student.StudentName}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Email:</p>
                <p className="detail">{student.StudentEmail}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Mobile:</p>
                <p className="detail">{student.StudentMobile}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Title:</p>
                <p className="detail">{student.eventTitle}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Organizer:</p>
                <p className="detail">{student.eventOrganizer}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Location:</p>
                <p className="detail">{student.eventLocation}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Mode:</p>
                <p className="detail">{student.eventMode}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Type:</p>
                <p className="detail">{student.eventType}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Entry Fee:</p>
                <p className="detail">{student.entryFee}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Event Date:</p>
                <p className="detail">
                  {new Date(student.eventDate.toDate()).toDateString()}
                </p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={() => handleDelete(student)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this student registration?`}
        />
      )}
    </>
  );
};

export default EventsStudentDetails;
