import React, { useState, useEffect, cloneElement } from "react";
import CategoryModal from "./CategoryModal";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "./AddServices.css";

const AddServices = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [addServiceModalOpen, setAddServiceModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [newServiceTitle, setNewServiceTitle] = useState(""); // Added state for new service title

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const snapshot = await firebase.firestore().collection("services").get();
      const fetchedCategories = snapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.id,
      }));
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories: ", error);
    }
  };

  const fetchServices = async (categoryId) => {
    try {
      const doc = await firebase.firestore().collection("services").doc(categoryId).get();
      if (doc.exists) {
        setServices(doc.data().services || []);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services: ", error);
    }
  };

  const openModal = (service = null) => {
    setSelectedService(service);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const openAddServiceModal = () => setAddServiceModalOpen(true);
  const closeAddServiceModal = () => setAddServiceModalOpen(false);

  const handleCategoryChange = (event) => {
    const selected = event.target.value;
    setSelectedCategory(selected);
    if (selected) {
      fetchServices(selected);
    } else {
      setServices([]);
    }
  };

  const handleAddNewService = async () => {
    if (!newServiceTitle.trim()) return;

    try {
      await firebase.firestore().collection("services").doc(newServiceTitle.trim()).set({
        services: []
      });

      setNewServiceTitle(""); // Reset new service title
      fetchCategories(); // Refresh categories after adding
      closeAddServiceModal();
    } catch (error) {
      console.error("Error adding new service category: ", error);
    }
  };

  const openConfirmModal = (service) => {
    setServiceToDelete(service);
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setServiceToDelete(null);
  };

  const handleDeleteService = async () => {
    try {
      const updatedServices = services.filter(s => s !== serviceToDelete);
      await firebase.firestore().collection("services").doc(selectedCategory).update({
        services: updatedServices
      });
      setServices(updatedServices);
      closeConfirmModal();
    } catch (error) {
      console.error("Error deleting service: ", error);
    }
  };

  return (
    <div className="add-services-container">
      <div className="top-controls">
        <div className="category-dropdown">
          <label htmlFor="category-select">Select Category:</label>
          <select id="category-select" onChange={handleCategoryChange} value={selectedCategory}>
            <option value="">-- Select a Category --</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '50px'}}>
        <button onClick={openAddServiceModal} className="add-service-button">
          Add Service
        </button>
        <button onClick={() => openModal()} className="add-category-button">
          Add Category
        </button>
        </div>

      {services.length > 0 && (
        <div className="services-list">
          <h3>Services under {selectedCategory}</h3>
          {services.map((service, index) => (
            <div key={index} className="service-item">
              <span className="service-title">{service.title}</span>
              <span className="service-price">{service.price}</span>
              <div>
                <button onClick={() => openModal(service)} className="edit-button">
                  Edit
                </button>
                <button onClick={() => openConfirmModal(service)} className="delete-button">
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {modalOpen && (
        <CategoryModal
          closeModal={closeModal}
          service={selectedService}
          category={selectedCategory}
          services={services}
          refreshServices={fetchServices} // Pass fetchServices as refreshServices
        />
      )}

      {addServiceModalOpen && (
        <div className="category-modal">
          <div className="modal-content">
            <h2>Add New Service Category</h2>
            <div className="form-group">
              <label>Service Category Title</label>
              <input
                type="text"
                value={newServiceTitle}
                onChange={(e) => setNewServiceTitle(e.target.value)}
              />
            </div>
            <div className="form-actions">
              <button onClick={handleAddNewService}>Save</button>
              <button onClick={closeAddServiceModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {confirmModalOpen && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <h2>Are you sure?</h2>
            <p>Do you really want to delete the service "{serviceToDelete?.title}"?</p>
            <div className="modal-actions">
              <button onClick={handleDeleteService} className="confirm-button">Yes</button>
              <button onClick={closeConfirmModal} className="cancel-button">No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddServices;
