import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./ChatPage.css";

const ChatPage = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Group chats by date
  const groupChatsByDate = (data) => {
    return data.reduce((acc, chat) => {
      const date = new Date(chat.lastMessageDate || 0).toLocaleDateString("en-US", {
        weekday: "long",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      if (!acc[date]) acc[date] = [];
      acc[date].push(chat);
      return acc;
    }, {});
  };

  // Group messages by date
  const groupMessagesByDate = (data) => {
    return data.reduce((acc, message) => {
      const date = new Date(message.createdAt?.seconds * 1000).toLocaleDateString("en-US", {
        weekday: "long",
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      if (!acc[date]) acc[date] = [];
      acc[date].push(message);
      return acc;
    }, {});
  };

  // Fetch all chats
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const snapshot = await firebase.firestore().collection("chats").get();
        const chatData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const chat = { id: doc.id, ...doc.data() };
    
            // Fetch user details for participants
            const participantPromises = chat.participants.map(async (userId) => {
              const userSnapshot = await firebase.firestore().collection("users").doc(userId).get();
              return userSnapshot.exists
                ? {
                    _id: userId,
                    name: userSnapshot.data()?.name || "Unknown User",
                    phoneNumber: userSnapshot.data()?.phoneNumber || "No Phone Number",
                  }
                : { _id: userId, name: "Unknown User", phoneNumber: "No Phone Number" };
            });
            const participants = await Promise.all(participantPromises);
    
            // Determine the latest message
            const messages = chat.messages || [];
            const lastMessage = messages.reduce((latest, message) =>
              (!latest || (message.createdAt?.seconds > latest.createdAt?.seconds)) ? message : latest,
              null
            );
    
            return {
              ...chat,
              participants,
              lastMessage, // Add the last message object
              lastMessageDate: lastMessage?.createdAt?.seconds * 1000 || 0,
            };
          })
        );
    
        // Sort chats by the latest message timestamp
        const sortedChats = chatData.sort((a, b) => b.lastMessageDate - a.lastMessageDate);
    
        setChats(sortedChats);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chats: ", error);
        setLoading(false);
      }
    };
    

    fetchChats();
  }, []);

  // Fetch messages for the selected chat
  useEffect(() => {
    if (!selectedChat) return;

    const unsubscribe = firebase
      .firestore()
      .collection("chats")
      .doc(selectedChat.id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const chatData = doc.data();
          const sortedMessages = (chatData.messages || []).sort(
            (a, b) => a.createdAt?.seconds - b.createdAt?.seconds
          );

          // Add user names to messages from selectedChat.participants
          const messagesWithUserDetails = sortedMessages.map((message) => {
            const user = selectedChat.participants.find(
              (participant) => participant._id === message.user._id
            );
            return {
              ...message,
              user: {
                name: user?.name || "Unknown User",
                phoneNumber: user?.phoneNumber || "No Phone Number",
              },
            };
          });

          setMessages(messagesWithUserDetails);
        }
      });

    return () => unsubscribe();
  }, [selectedChat]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "No timestamp";
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  };

  const filteredChats = chats.filter((chat) =>
    chat.participants.some(
      (participant) =>
        participant.name && participant.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  

  if (loading) {
    return <div className="loading">Loading chats...</div>;
  }

  return (
    <div className="admin-chat-page">
      {!selectedChat ? (
        <div className="chat-list">
          <h2>User Chats</h2>
          <input
            type="text"
            placeholder="Search by user name"
            className="search-bar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {Object.entries(groupChatsByDate(filteredChats)).map(([date, groupedChats]) => (
            <div key={date}>
              <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                <div className="date-header">{date}</div>
              </div>
              {groupedChats.map((chat) => (
                <div key={chat.id} className="chat-item" onClick={() => handleChatClick(chat)}>
                  {/* <h3>
                      Users:{" "}
                      {chat.participants
                        .map(
                          (participant) =>
                            `${participant.name} (${participant.phoneNumber || "No Phone Number"})`
                        )
                        .join(", ")}
                    </h3> */}

                  <h3>
                    Users:{" "}
                    {chat.participants.map((participant) => participant.name).join(", ")}
                  </h3>

                  <p>{chat.lastMessage?.text || "No messages"}</p> 
                  <p className="timestamp">
                    {chat.lastMessage?.createdAt ? formatTimestamp(chat.lastMessage.createdAt) : ""}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="chat-detail">
          <div className="chat-header">
            <button onClick={() => setSelectedChat(null)} className="back-button">
              Back
            </button>
            <h3>
                Chat with:{" "}
                {selectedChat.participants
                  .map(
                    (participant) =>
                      `${participant.name} (${participant.phoneNumber || "No Phone Number"})`
                  )
                  .join(", ")}
              </h3>
          </div>
          <div className="chat-messages">
            {Object.entries(groupMessagesByDate(messages)).map(([date, groupedMessages]) => (
              <div key={date}>
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                  <div className="date-header">{date}</div>
                </div>
                {groupedMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${
                      message.user._id === "5EDdmdH68bNEVCf3hT4pKxpTy2R2" // Replace with the admin's _id
                        ? "sender-message"
                        : "receiver-message"
                    }`}
                  >
                    <div className="message-bubble">
                      <span className="message-user-name">
                        {message.user.name || "Unknown User"}
                      </span>
                      {message.text}
                      <span className="timestamp">{formatTimestamp(message.createdAt)}</span>
                    </div>
                  </div>
                ))}


              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
