import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const OpportunityInternDetails = () => {
  const [internRegistrations, setInternRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    intern: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInterns, setFilteredInterns] = useState([]);

  useEffect(() => {
    const fetchInternRegistrations = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("internRegistrations")
          .orderBy("createdAt", "desc")
          .get();
        const interns = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = interns.map((intern) => intern.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setInternRegistrations(interns);
        setFilteredInterns(interns);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching intern registrations: ", error);
        setLoading(false);
      }
    };

    fetchInternRegistrations();
  }, []);

  useEffect(() => {
    setFilteredInterns(
      internRegistrations.filter((intern) => {
        const userDetail = userDetails[intern.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          intern.InternName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          intern.InternEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          intern.InternMobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          intern.CompanyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          intern.Role?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [searchTerm, internRegistrations, userDetails]);

  const handleDelete = (intern) => {
    setModalState({ show: true, intern });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("internRegistrations")
        .doc(modalState.intern.id)
        .delete();
      setInternRegistrations((prev) =>
        prev.filter((item) => item.id !== modalState.intern.id)
      );
      setFilteredInterns((prev) =>
        prev.filter((item) => item.id !== modalState.intern.id)
      );
      setModalState({ show: false, intern: null });
    } catch (error) {
      console.error("Error deleting intern registration: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, intern: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Interns: {filteredInterns.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Interns..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredInterns.map((intern) => {
          const userDetail = userDetails[intern.userId] || {};
          return (
            <div key={intern.id} className="contentItemEvents">
              <div className="user-info-container">
                <img
                  src={userDetail.profileImage || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="name label detail">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Intern Name:</p>
                <p className="detail">{intern.InternName}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Email:</p>
                <p className="detail">{intern.InternEmail}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Mobile:</p>
                <p className="detail">{intern.InternMobile}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Company:</p>
                <p className="detail">{intern.CompanyName}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Role:</p>
                <p className="detail">{intern.Role}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Company Location:</p>
                <p className="detail">{intern.CompanyLocation}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Salary:</p>
                <p className="detail">{intern.Salary}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Shift:</p>
                <p className="detail">{intern.Shift}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">CV:</p>
                <p className="detail view-btn">
                  <a href={intern.cv} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={() => handleDelete(intern)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this intern registration?`}
        />
      )}
    </>
  );
};

export default OpportunityInternDetails;
