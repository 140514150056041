import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const DetailsModal = ({ show, onClose, auditor, userDetail, onSave }) => {
  const [editAuditor, setEditAuditor] = useState(auditor);

  useEffect(() => {
    setEditAuditor(auditor);
  }, [auditor]);

  const handleFieldChange = (field, value) => {
    setEditAuditor((prev) => ({ ...prev, [field]: value }));
  };

  const handlePracticeAreaChange = (index, value) => {
    setEditAuditor((prev) => {
      const updatedPracticeAreas = [...prev.practiceAreas];
      updatedPracticeAreas[index] = value;
      return { ...prev, practiceAreas: updatedPracticeAreas };
    });
  };
  
  const addPracticeArea = () => {
    setEditAuditor((prev) => ({
      ...prev,
      practiceAreas: [...(prev.practiceAreas || []), ""],
    }));
  };
  
  const removePracticeArea = (index) => {
    setEditAuditor((prev) => {
      const updatedPracticeAreas = [...prev.practiceAreas];
      updatedPracticeAreas.splice(index, 1);
      return { ...prev, practiceAreas: updatedPracticeAreas };
    });
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-payment" style={{zIndex: '99999', overflowY: 'auto',}}>
      <div className="modal-content-payment" style={{overflowY: 'auto', maxHeight: '80vh'}}>
        <h2>Edit Auditor Details</h2>
        <div className="detail-container">
          <p className="label">Name:</p>
          <input
            className="detail"
            value={editAuditor.fullName}
            onChange={(e) => handleFieldChange("fullName", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">City:</p>
          <input
            className="detail"
            value={editAuditor.residentialAddress.city}
            onChange={(e) =>
              handleFieldChange("residentialAddress", {
                ...editAuditor.residentialAddress,
                city: e.target.value,
              })
            }
          />
        </div>
        <div className="detail-container">
          <p className="label">Enrollment ID:</p>
          <input
            className="detail"
            value={editAuditor.enrollmentId}
            onChange={(e) => handleFieldChange("enrollmentId", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Experience:</p>
          <input
            className="detail"
            value={editAuditor.experience}
            onChange={(e) => handleFieldChange("experience", e.target.value)}
          />
        </div>
        <div className="detail-container">
          <p className="label">Service Fee:</p>
          <input
            className="detail"
            value={editAuditor.serviceFee}
            onChange={(e) => handleFieldChange("serviceFee", e.target.value)}
          />
        </div>
        <div className="detail-container" style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
            <p className="label" style={{ marginBottom: "10px" }}>Practice Areas:</p>
            <button
              type="button"
              onClick={addPracticeArea}
              style={{
                marginTop: "10px",
                backgroundColor: "#28A745",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "5px 15px",
                fontSize: "18px",
                cursor: "pointer",
                width: '50px',
              }}
            >
              +
            </button>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", }}>
            
              {editAuditor.practiceAreas?.map((area, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    className="detail"
                    style={{
                      flex: 1,
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "14px",
                      marginBottom: '10px',
                    }}
                    value={area}
                    onChange={(e) => handlePracticeAreaChange(index, e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => removePracticeArea(index)}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      padding: "5px 15px",
                      fontSize: "14px",
                      cursor: "pointer",
                      marginTop: 0,
                    }}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
            
          </div>

        <div className="modal-actions">
          <button onClick={() => onSave(editAuditor)} className="modal-button">
            Save
          </button>
          <button onClick={onClose} className="modal-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};


const AuditorsScreen = () => {
  const [approvedAuditors, setApprovedAuditors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    auditor: null,
  });
  const [detailsModalState, setDetailsModalState] = useState({
    show: false,
    auditor: null,
    isEditMode: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAuditors, setFilteredAuditors] = useState([]);

  useEffect(() => {
    const fetchApprovedAuditors = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedAuditors")
          .orderBy("createdAt", "desc")
          .get();
        const auditors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch user details for each auditor
        const userIds = auditors.map((auditor) => auditor.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedAuditors(auditors);
        setFilteredAuditors(auditors);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved auditors: ", error);
        setLoading(false);
      }
    };

    fetchApprovedAuditors();
  }, []);

  useEffect(() => {
    setFilteredAuditors(
      approvedAuditors.filter((auditor) => {
        const userDetail = userDetails[auditor.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.residentialAddress.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.enrollmentId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.experience?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.practiceAreas.some((area) =>
            area.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
    );
  }, [searchTerm, approvedAuditors, userDetails]);

  const handleDelete = (auditor) => {
    setModalState({ show: true, auditor });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedAuditors")
        .doc(modalState.auditor.id)
        .delete();
      setApprovedAuditors((prev) =>
        prev.filter((item) => item.id !== modalState.auditor.id)
      );
      setFilteredAuditors((prev) =>
        prev.filter((item) => item.id !== modalState.auditor.id)
      );
      setModalState({ show: false, auditor: null });
    } catch (error) {
      console.error("Error deleting auditor: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, auditor: null });
  };

  const handleItemClick = (auditor) => {
    setDetailsModalState({ show: true, auditor });
  };

  const handleEdit = (auditor) => {
    setDetailsModalState({ show: true, auditor, isEditMode: true });
  };

  const handleSave = async (updatedAuditor) => {
    try {
      await firebase.firestore().collection("approvedAuditors").doc(updatedAuditor.id).update(updatedAuditor);
      setApprovedAuditors((prev) =>
        prev.map((aud) => (aud.id === updatedAuditor.id ? updatedAuditor : aud))
      );
      setDetailsModalState({ show: false, auditor: null, isEditMode: false });
      window.alert("Auditor details updated successfully.");
    } catch (error) {
      console.error("Error updating auditor details: ", error);
      window.alert("There was an error updating the details. Please try again.");
    }
  };

  const closeDetailsModal = () => {
    setDetailsModalState({ show: false, auditor: null, isEditMode: false });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Auditors: {filteredAuditors.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Auditors..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredAuditors.map((auditor) => {
          const userDetail = userDetails[auditor.userId] || {};
          return (
            <div
              key={auditor.id}
              className="contentItem"
              onClick={() => handleItemClick(auditor)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={auditor.profilePicture || require('./Assets/user-icon.png')}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <h3 className="name label detail">Joined At:</h3>
                <h3 className="detail">
                  {auditor.createdAt
                    ? new Date(auditor.createdAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : auditor.updatedAt
                    ? new Date(auditor.updatedAt.seconds * 1000).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: true,
                      })
                    : "Timestamp not available"}
                </h3>
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{auditor.fullName}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">City:</p>
                <p className="detail">{auditor.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Membership ID:</p>
                <p className="detail">{auditor.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Experience:</p>
                <p className="detail">{auditor.experience}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Service Fee:</p>
                <p className="detail">
                  ₹ {auditor.serviceFee} 
                </p>
              </div>
              <div className="buttonContainer">
              <button
                  className="edit-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up
                    handleEdit(auditor); // Calls handleEdit function
                  }}
                  style={{ marginTop: 10 }}
                >
                  Edit
                </button>
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent
                    handleDelete(auditor);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this auditor?`}
        />
      )}
      {detailsModalState.show && (
        <DetailsModal
        show={detailsModalState.show}
        onClose={closeDetailsModal}
        auditor={detailsModalState.auditor}
        userDetail={userDetails[detailsModalState.auditor.userId] || {}}
        onSave={handleSave}
      />
      )}
    </>
  );
};

export default AuditorsScreen;
