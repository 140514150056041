import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "./LoginPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

const ADMIN_EMAIL = "admin@lexdoo.com";
const ADMIN_PASSWORD = "Admin@Lex";

const LoginPage = ({ setLoggedIn, setIsAdmin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Check localStorage for stored admin status
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    if (isAdmin && isLoggedIn) {
      setIsAdmin(true);
      setLoggedIn(true);
    }
  }, [setIsAdmin, setLoggedIn]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const user = userCredential.user;

      // Check if email and password match the hardcoded admin credentials
      if (email === ADMIN_EMAIL && password === ADMIN_PASSWORD) {
        setIsAdmin(true);
        setLoggedIn(true);
        localStorage.setItem("isAdmin", "true");
        localStorage.setItem("isLoggedIn", "true");
      } else {
        setError("You do not have admin privileges.");
        await firebase.auth().signOut();
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("isLoggedIn");
      }
    } catch (err) {
      setError("Login failed. Please check your email and password.");
    }
  };

  const handleLogout = () => {
    firebase.auth().signOut();
    setIsAdmin(false);
    setLoggedIn(false);
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("isLoggedIn");
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <div className="form-group">
          <FontAwesomeIcon icon={faUser} className="icon" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Username"
            required
          />
        </div>
        <div className="form-group">
          <FontAwesomeIcon icon={faLock} className="icon" />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
        </div>
        <button type="submit">Login Now</button>
      </form>
      {/* <button onClick={handleLogout}>Logout</button> */}
    </div>
  );
};

export default LoginPage;
