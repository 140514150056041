import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch, faEdit } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const BlogsScreen = () => {
  const [approvedBlogs, setApprovedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({
    show: false,
    blog: null,
  });
  const [editModalState, setEditModalState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [shares, setShares] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    const fetchApprovedBlogs = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedBlogs")
          .orderBy("createdAt", "desc")
          .get();
        const blogs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setApprovedBlogs(blogs);
        setFilteredBlogs(blogs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved blogs: ", error);
        setLoading(false);
      }
    };

    fetchApprovedBlogs();
  }, []);

  useEffect(() => {
    setFilteredBlogs(
      approvedBlogs.filter((blog) =>
        blog.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.author?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.id?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, approvedBlogs]);

  const handleDelete = (blog) => {
    setModalState({ show: true, blog });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedBlogs")
        .doc(modalState.blog.id)
        .delete();
      setApprovedBlogs((prev) =>
        prev.filter((item) => item.id !== modalState.blog.id)
      );
      setFilteredBlogs((prev) =>
        prev.filter((item) => item.id !== modalState.blog.id)
      );
      setModalState({ show: false, blog: null });
    } catch (error) {
      console.error("Error deleting blog: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, blog: null });
  };

  const handleEditCounts = (blog) => {
    setSelectedBlog(blog);
    setLikes(blog.likes || 0);
    setDislikes(blog.dislikes || 0);
    setShares(blog.shareCount || 0);
    setClicks(blog.clicksCount || 0);
    setEditModalState(true);
  };

  const saveCounts = async () => {
    if (selectedBlog) {
      try {
        await firebase
          .firestore()
          .collection("approvedBlogs")
          .doc(selectedBlog.id)
          .update({
            likes: parseInt(likes, 10),
            dislikes: parseInt(dislikes, 10),
            shareCount: parseInt(shares, 10),
            clicksCount: parseInt(clicks, 10),
          });

        setApprovedBlogs((prev) =>
          prev.map((item) =>
            item.id === selectedBlog.id
              ? { ...item, likes: parseInt(likes, 10), dislikes: parseInt(dislikes, 10), shareCount: parseInt(shares, 10), clicksCount: parseInt(clicks, 10) }
              : item
          )
        );

        setFilteredBlogs((prev) =>
          prev.map((item) =>
            item.id === selectedBlog.id
              ? { ...item, likes: parseInt(likes, 10), dislikes: parseInt(dislikes, 10), shareCount: parseInt(shares, 10), clicksCount: parseInt(clicks, 10) }
              : item
          )
        );

        setEditModalState(false);
        setSelectedBlog(null);
      } catch (error) {
        console.error("Error updating counts: ", error);
      }
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Blogs: {filteredBlogs.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Blogs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainerBlogs">
        {filteredBlogs.map((blog) => (
          <div key={blog.id} className="contentItemBlogs">
            <h3 className="title">{blog.title}</h3>
            <p>{blog.content}</p>
            <div
              className="userInfo"
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div>
                <img
                  src={blog.imageUrl}
                  alt={blog.author}
                  className="profileImageBlogs"
                />
              </div>
              <div>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Author: </strong>
                  {blog.author}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Date: </strong>
                  {blog.date}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>ID: </strong>
                  {blog.id}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Image Url: </strong>
                  {blog.imageUrl}
                </p>
              </div>
            </div>
            <div className="counts-container" style={{ display: "flex", gap: "20px", alignItems: 'center', justifyContent: 'center', margin: '15px 0' }}>
              <span>Likes: {blog.likes || 0}</span>
              <span>Dislikes: {blog.dislikes || 0}</span>
              <span>Shares: {blog.shareCount || 0}</span>
              <span>Clicks: {blog.clicksCount || 0}</span>
              <button className="edit-icon" onClick={() => handleEditCounts(blog)} style={{marginTop: 0, backgroundColor: '#eee', padding: '10px 20px'}}>
              <FontAwesomeIcon icon={faEdit} color="green" fontSize={20}/>
              </button>
              <button
                className="delete-icon"
                onClick={() => handleDelete(blog)}
                style={{marginTop: 0, backgroundColor: '#eee', padding: '10px 20px'}}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this blog?`}
        />
      )}
      {editModalState && (
       <div className="modal-payment">
       <div className="modal-content-payment">
         <h3>Edit Counts</h3>
         <div className="input-group">
           <label htmlFor="likes">Likes</label>
           <input
             type="number"
             id="likes"
             value={likes}
             onChange={(e) => setLikes(e.target.value)}
             placeholder="Likes"
           />
         </div>
         <div className="input-group">
           <label htmlFor="dislikes">Dislikes</label>
           <input
             type="number"
             id="dislikes"
             value={dislikes}
             onChange={(e) => setDislikes(e.target.value)}
             placeholder="Dislikes"
           />
         </div>
         <div className="input-group">
           <label htmlFor="shares">Shares</label>
           <input
             type="number"
             id="shares"
             value={shares}
             onChange={(e) => setShares(e.target.value)}
             placeholder="Shares"
           />
         </div>
         <div className="input-group">
           <label htmlFor="clicks">Clicks</label>
           <input
             type="number"
             id="clicks"
             value={clicks}
             onChange={(e) => setClicks(e.target.value)}
             placeholder="Clicks"
           />
         </div>
         <div className="modal-buttons">
           <button className="button button-save" onClick={saveCounts}>
             Save
           </button>
           <button
             className="button button-cancel"
             onClick={() => setEditModalState(false)}
           >
             Cancel
           </button>
         </div>
       </div>
     </div>
     
      )}
    </>
  );
};

export default BlogsScreen;
