import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Loader from "./Loader";
import "./UsersScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faSearch, faTrashAlt, faUser } from "@fortawesome/free-solid-svg-icons";

const ReferalRequests = () => {
  const [requestsByDate, setRequestsByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRequestsByDate, setFilteredRequestsByDate] = useState({});

  const groupRequestsByDate = (requests) => {
    return requests.reduce((groups, request) => {
      const date = request.referredAt
        ? new Date(request.referredAt.seconds * 1000).toLocaleDateString()
        : "No Date";
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(request);
      return groups;
    }, {});
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDoc = await firebase.firestore().collection("users").doc(userId).get();
      return userDoc.exists
        ? {
            name: userDoc.data()?.name || "Unknown",
            phoneNumber: userDoc.data()?.phoneNumber || "Not Provided",
            email: userDoc.data()?.email || "Not Provided",
          }
        : {};
    } catch (error) {
      console.error("Error fetching user details: ", error);
      return { name: "Unknown", phoneNumber: "Not Provided", email: "Not Provided" };
    }
  };

  const handleDeleteRequest = async (requestId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this referral?");
    if (confirmDelete) {
      try {
        await firebase.firestore().collection("Referals").doc(requestId).delete();

        setRequestsByDate((prev) => {
          const updatedRequests = { ...prev };
          Object.keys(updatedRequests).forEach((date) => {
            updatedRequests[date] = updatedRequests[date].filter((request) => request.id !== requestId);
            if (updatedRequests[date].length === 0) {
              delete updatedRequests[date];
            }
          });
          return updatedRequests;
        });

        setFilteredRequestsByDate((prev) => {
          const updatedRequests = { ...prev };
          Object.keys(updatedRequests).forEach((date) => {
            updatedRequests[date] = updatedRequests[date].filter((request) => request.id !== requestId);
            if (updatedRequests[date].length === 0) {
              delete updatedRequests[date];
            }
          });
          return updatedRequests;
        });

        alert("Referral deleted successfully.");
      } catch (error) {
        console.error("Error deleting referral: ", error);
        alert("There was an error deleting the referral. Please try again.");
      }
    }
  };

  useEffect(() => {
    const fetchReferals = async () => {
      try {
        const snapshot = await firebase.firestore().collection("Referals").get();
        const referralsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const referral = { id: doc.id, ...doc.data() };

            // Fetch referrer and referred user details
            const referrerDetails = await fetchUserDetails(referral.referrerId);
            const referredUserDetails = await fetchUserDetails(referral.referredUserId);

            return {
              ...referral,
              referrerName: referrerDetails.name,
              referrerPhoneNumber: referrerDetails.phoneNumber,
              referrerEmail: referrerDetails.email,
              referredName: referredUserDetails.name,
              referredPhoneNumber: referredUserDetails.phoneNumber,
              referredEmail: referredUserDetails.email,
            };
          })
        );

        // Sort referrals by referredAt date in descending order
        const sortedReferrals = referralsData.sort((a, b) => {
          const aTimestamp = a.referredAt ? a.referredAt.seconds : 0;
          const bTimestamp = b.referredAt ? b.referredAt.seconds : 0;
          return bTimestamp - aTimestamp;
        });

        // Group referrals by date
        const groupedReferrals = groupRequestsByDate(sortedReferrals);
        setRequestsByDate(groupedReferrals);
        setFilteredRequestsByDate(groupedReferrals);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching referrals: ", error);
        setLoading(false);
      }
    };

    fetchReferals();
  }, []);

  useEffect(() => {
    const filteredRequests = {};
    Object.keys(requestsByDate).forEach((date) => {
      const requests = requestsByDate[date].filter((request) =>
        request.referredName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.referredEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.referredPhoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (requests.length) {
        filteredRequests[date] = requests;
      }
    });
    setFilteredRequestsByDate(filteredRequests);
  }, [searchTerm, requestsByDate]);

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Referrals: {Object.keys(filteredRequestsByDate).reduce((total, date) => total + filteredRequestsByDate[date].length, 0)}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Referrals..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="users-screen">
        <div className="users-container">
          {Object.keys(filteredRequestsByDate).map((date) => (
            <div key={date} className="date-group">
              <h3>{date} - {filteredRequestsByDate[date].length} Referrals</h3>
              <div className="users-row">
                {filteredRequestsByDate[date].map((request) => (
                  <div key={request.id} className="user-card">
                    <div className="user-details">
                      <h3>Referred User:</h3>
                      <p><FontAwesomeIcon icon={faUser} /> {request.referredName}</p>
                      <p><FontAwesomeIcon icon={faPhone} /> {request.referredPhoneNumber}</p>
                      <p><FontAwesomeIcon icon={faEnvelope} /> {request.referredEmail}</p>
                      
                      <h3>Referrer User:</h3>
                      <p><FontAwesomeIcon icon={faUser} /> {request.referrerName}</p>
                      <p><FontAwesomeIcon icon={faPhone} /> {request.referrerPhoneNumber}</p>
                      <p><FontAwesomeIcon icon={faEnvelope} /> {request.referrerEmail}</p>

                      <h4 style={{marginBottom: 0}}>Referred At: {request.referredAt
                        ? new Date(request.referredAt.seconds * 1000).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                          })
                        : 'Unknown'}
                      </h4>
                    </div>
                    <div className="button-container">
                      <button
                        className="button-delete"
                        onClick={() => handleDeleteRequest(request.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReferalRequests;
