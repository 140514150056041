import React, { useState } from "react";
import "./Payments.css"; // For consistent styling
import NewsScreen from "./NewsScreen";
import BlogsScreen from "./BlogScreen";
import VideosScreen from "./VideosScreen";
import NewsUpload from "./NewsUpload";
import Banners from "./Banners";
import BannerUpload from "./BannerUpload";

const HomeBanners = () => {
  const [activeTab, setActiveTab] = useState("Banners");

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "Banners":
        return <Banners />;
      case "Banners Upload":
        return <BannerUpload />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="sub-tabs">
        <div
          className={`sub-tab ${activeTab === "Banners" ? "active" : ""}`}
          onClick={() => setActiveTab("Banners")}
        >
          Banners
        </div>
        <div
          className={`sub-tab ${activeTab === "Banners Upload" ? "active" : ""}`}
          onClick={() => setActiveTab("Banners Upload")}
        >
          Banners Upload
        </div>
      </div>

      <div className="sub-tab-content">{renderActiveTabContent()}</div>
    </div>
  );
};

export default HomeBanners;
