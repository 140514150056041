import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const ScholarshipList = () => {
  const [scholarships, setScholarships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredScholarships, setFilteredScholarships] = useState([]);
  const [modalState, setModalState] = useState({
    show: false,
    scholarship: null,
  });

  useEffect(() => {
    const fetchScholarships = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("scholarship")
          .orderBy("createdAt", "desc")
          .get();
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setScholarships(data);
        setFilteredScholarships(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching scholarships: ", error);
        setLoading(false);
      }
    };

    fetchScholarships();
  }, []);

  useEffect(() => {
    setFilteredScholarships(
      scholarships.filter((scholarship) =>
        [
          scholarship.title,
          scholarship.providedBy,
          scholarship.category,
          scholarship.scholarshipType,
          scholarship.providerName,
          scholarship.providerEmail,
          scholarship.providerMobile,
          scholarship.eligibility,
        ]
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, scholarships]);

  const handleDelete = (scholarship) => {
    setModalState({ show: true, scholarship });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("scholarship")
        .doc(modalState.scholarship.id)
        .delete();
      setScholarships((prev) =>
        prev.filter((item) => item.id !== modalState.scholarship.id)
      );
      setFilteredScholarships((prev) =>
        prev.filter((item) => item.id !== modalState.scholarship.id)
      );
      setModalState({ show: false, scholarship: null });
    } catch (error) {
      console.error("Error deleting scholarship: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, scholarship: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Scholarships: {filteredScholarships.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Scholarships..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredScholarships.map((scholarship) => (
          <div key={scholarship.id} className="contentItemEvents">
            <div className="detail-container">
              <p className="name label detail">Title:</p>
              <p className="detail">{scholarship.title}</p>
            </div>
            <div className="detail-container">
              <p className="name label detail">Provided By:</p>
              <p className="detail">{scholarship.providedBy}</p>
            </div>
            <div className="detail-container">
              <p className="name label detail">Category:</p>
              <p className="detail">{scholarship.category}</p>
            </div>
            <div className="detail-container">
              <p className="name label detail">Type:</p>
              <p className="detail">{scholarship.scholarshipType}</p>
            </div>
            {scholarship.scholarshipType === "Paid" && (
              <div className="detail-container">
                <p className="name label detail">Amount:</p>
                <p className="detail">₹{scholarship.amount}</p>
              </div>
            )}
            {scholarship.validity && (
              <div className="detail-container">
                <p className="name label detail">Validity:</p>
                <p className="detail">
                  {new Date(
                    scholarship.validity.seconds * 1000
                  ).toDateString()}
                </p>
              </div>
            )}
            <div className="detail-container">
              <p className="name label detail">Provider Name:</p>
              <p className="detail">{scholarship.providerName}</p>
            </div>
            <div className="detail-container">
              <p className="name label detail">Provider Email:</p>
              <p className="detail">{scholarship.providerEmail}</p>
            </div>
            <div className="detail-container">
              <p className="name label detail">Provider Mobile:</p>
              <p className="detail">{scholarship.providerMobile}</p>
            </div>
            <div className="detail-container">
              <p className="name label detail">Eligibility:</p>
              <p className="detail">{scholarship.eligibility}</p>
            </div>
            {scholarship.logoUrl && (
              <div className="detail-container">
                <img
                  src={scholarship.logoUrl}
                  alt="Logo"
                  className="scholarshipLogo"
                  style={{width: 250}}
                />
              </div>
            )}
            <div className="buttonContainer">
              <button
                className="delete-icon"
                onClick={() => handleDelete(scholarship)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this scholarship?`}
        />
      )}
    </>
  );
};

export default ScholarshipList;
