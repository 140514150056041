import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Loader from "./Loader";
import "./UsersScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMoneyBillAlt, faCalendar, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const AddedBalance = () => {
  const [balancesByDate, setBalancesByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBalancesByDate, setFilteredBalancesByDate] = useState({});

  // Group balances by date
  const groupBalancesByDate = (balances) => {
    return balances.reduce((groups, balance) => {
      const date = balance.createdAt
        ? balance.createdAt.toDate().toLocaleDateString()
        : "No Date";
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(balance);
      return groups;
    }, {});
  };

  const handleDeleteBalance = async (balanceId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this record?");
    if (confirmDelete) {
      try {
        // Delete the balance record from Firestore
        await firebase.firestore().collection("AddedBalances").doc(balanceId).delete();

        // Update state after deletion
        setBalancesByDate((prev) => {
          const updatedBalances = { ...prev };
          Object.keys(updatedBalances).forEach((date) => {
            updatedBalances[date] = updatedBalances[date].filter((balance) => balance.id !== balanceId);
            if (updatedBalances[date].length === 0) {
              delete updatedBalances[date]; // Remove the date if no records remain
            }
          });
          return updatedBalances;
        });

        setFilteredBalancesByDate((prev) => {
          const updatedBalances = { ...prev };
          Object.keys(updatedBalances).forEach((date) => {
            updatedBalances[date] = updatedBalances[date].filter((balance) => balance.id !== balanceId);
            if (updatedBalances[date].length === 0) {
              delete updatedBalances[date]; // Remove the date if no records remain
            }
          });
          return updatedBalances;
        });

        alert("Record deleted successfully.");
      } catch (error) {
        console.error("Error deleting record:", error);
        alert("There was an error deleting the record. Please try again.");
      }
    }
  };

  useEffect(() => {
    const fetchAddedBalances = async () => {
      try {
        const snapshot = await firebase.firestore().collection("AddedBalances").get();
        const balancesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort balances by 'createdAt' field in descending order
        const sortedBalances = balancesData.sort((a, b) => {
          const aCreatedAt = a.createdAt ? a.createdAt.toMillis() : 0;
          const bCreatedAt = b.createdAt ? b.createdAt.toMillis() : 0;
          return bCreatedAt - aCreatedAt;
        });

        const groupedBalances = groupBalancesByDate(sortedBalances);
        setBalancesByDate(groupedBalances);
        setFilteredBalancesByDate(groupedBalances);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching added balances:", error);
        setLoading(false);
      }
    };

    fetchAddedBalances();
  }, []);

  useEffect(() => {
    const filteredBalances = {};
    Object.keys(balancesByDate).forEach((date) => {
      const balances = balancesByDate[date].filter((balance) =>
        balance.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        balance.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        balance.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (balances.length) {
        filteredBalances[date] = balances;
      }
    });
    setFilteredBalancesByDate(filteredBalances);
  }, [searchTerm, balancesByDate]);

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Added Balances: {Object.keys(filteredBalancesByDate).reduce((total, date) => total + filteredBalancesByDate[date].length, 0)}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Balances..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="users-screen">
        <div className="users-container">
          {Object.keys(filteredBalancesByDate).map((date) => (
            <div key={date} className="date-group">
              <h3>{date} - {filteredBalancesByDate[date].length} Records</h3>
              <div className="users-row">
                {filteredBalancesByDate[date].map((balance) => (
                  <div key={balance.id} className="user-card">
                    <div className="user-details">
                      <h2>{balance.name}</h2>
                      <p><FontAwesomeIcon icon={faEnvelope} /> {balance.email}</p>
                      <p><FontAwesomeIcon icon={faPhone} /> {balance.phoneNumber}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Added Coins: {balance.addedCoins}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Total Balance: {balance.balance}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Previous Balance: {balance.previousBalance}</p>
                      <p><FontAwesomeIcon icon={faMoneyBillAlt} /> Updated Balance: {balance.updatedBalance}</p>
                      <p><FontAwesomeIcon icon={faCalendar} /> Added At: {balance.createdAt
                        ? new Date(balance.createdAt.seconds * 1000).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true
                          })
                        : 'Unknown'}</p>
                    </div>
                    <div className="button-container">
                      <button
                        className="button-delete"
                        onClick={() => handleDeleteBalance(balance.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddedBalance;
