import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./AddServices.css";


const LexiAiInstructions = () => {
  const [instructions, setInstructions] = useState([]);
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newInstruction, setNewInstruction] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchInstructions();
  }, []);

  const fetchInstructions = async () => {
    try {
      const snapshot = await firebase.firestore().collection("LexiAiInstruction").get();
      const fetchedInstructions = snapshot.docs.map((doc) => ({
        id: doc.id,
        instruction: doc.data().instruction,
      }));
      setInstructions(fetchedInstructions);
    } catch (error) {
      console.error("Error fetching instructions: ", error);
    }
  };

  const openModal = (instruction = null) => {
    setSelectedInstruction(instruction);
    setNewInstruction(instruction ? instruction.instruction : "");
    setEditMode(instruction !== null);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleSaveInstruction = async () => {
    if (!newInstruction.trim()) return;

    try {
      if (editMode) {
        await firebase.firestore().collection("LexiAiInstruction").doc(selectedInstruction.id).update({
          instruction: newInstruction.trim(),
        });
      } else {
        await firebase.firestore().collection("LexiAiInstruction").add({
          instruction: newInstruction.trim(),
        });
      }
      setNewInstruction("");
      closeModal();
      fetchInstructions();
    } catch (error) {
      console.error("Error saving instruction: ", error);
    }
  };

  const handleDeleteInstruction = async (id) => {
    try {
      await firebase.firestore().collection("LexiAiInstruction").doc(id).delete();
      fetchInstructions();
    } catch (error) {
      console.error("Error deleting instruction: ", error);
    }
  };

  return (
    <div className="lexi-ai-instructions-container">
      {!instructions.length && (
        <button onClick={() => openModal()} className="add-instruction-button">
          Add Instruction
        </button>
      )}

      {instructions.length > 0 && (
        <div className="services-list">
          <h3>Lexi AI Instructions</h3>
          {instructions.map((instruction) => (
            <div key={instruction.id} className="service-item" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>
              <span>{instruction.instruction}</span>
              <div>
                <button onClick={() => openModal(instruction)} className="edit-button">
                  Edit
                </button>
                <button onClick={() => handleDeleteInstruction(instruction.id)} className="delete-button">
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {modalOpen && (
        <div className="category-modal">
          <div className="modal-content">
            <h2>{editMode ? "Edit Instruction" : "Add New Instruction"}</h2>
            <div className="form-group">
            <textarea
            value={newInstruction}
            onChange={(e) => setNewInstruction(e.target.value)}
            style={{height: '200px'}}
            placeholder="Enter LexiAi Instructions Here"
            />
            </div>
            <div className="form-actions">
              <button onClick={handleSaveInstruction}>
                {editMode ? "Update" : "Save"}
              </button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LexiAiInstructions;
